import React from 'react';
import { useAuth } from '../../context/auth/Auth';
import { useHistory } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import styles from './NavMenu.module.css';

const NavMenu: React.FC = () => {
    const { logout } = useAuth();
    const history = useHistory();

    const onLogoutClicked = () => {
        logout();
        history.push('/login');
    };

    return (
        <Navbar className={`px-4 py-3 ${styles.navbar}`} bg="dark" variant="dark" collapseOnSelect expand="md">
            <Navbar.Brand className={styles.title} href="/">Employee Skills Tracker</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
                <Nav className="me-auto">
                    <Nav.Link className={`mx-1 ${styles.navButton}`} bsPrefix='navButton' href="/search/employees">Search</Nav.Link>
                    <Nav.Link className={`mx-1 ${styles.navButton}`} bsPrefix='navButton' href="/profile/edit">My Profile</Nav.Link>
                    <Nav.Link className={`mx-1 ${styles.navButton}`} bsPrefix='navButton' onClick={onLogoutClicked}>Logout</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavMenu;