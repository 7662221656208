import React from 'react';
import { Col, Row } from 'react-bootstrap';

//Style
import styles from './SkillLegend.module.css';


interface SkillLegendProps {
    legends?: any[]
}

const defaultLegends = [
    {label: 'Beginner', color: '#F87171'},
    {label: 'Intermediate', color: '#FDE68A'},
    {label: 'Expert', color: '#34D399'}
];


const SkillLegend: React.FC<SkillLegendProps> = (props: SkillLegendProps) => {
    const legends = (props.legends && props.legends.length > 0) ? props.legends : defaultLegends;

    return (
        <Row>
            {legends.map((legend: any, index: number) => {
                return (
                    <Col className='col-auto' key={index}>
                        <Row className={`mx-0 ${styles.legendContainer}`}>
                            <div style={{backgroundColor: legend.color}} className={`mr-2 ${styles.circle}`}></div>
                            <div className={styles.label}>{legend.label}</div>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );
};

export default SkillLegend;
