import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import styles from './ProjectCard.module.css';
import Moment from 'react-moment';
import _ from 'lodash';

import SkillChip from '../SkillChip/SkillChip';

import {
    FaCode,
    FaRegCalendarAlt,
    FaRegFileCode,
    FaRegHandshake,
    FaRegUserCircle
} from 'react-icons/fa';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';

interface Props {
    project: any
}

const EmployeeChip: React.FC<{ employee: any }> = (props) => {
    const { employee } = props;

    return (
        <>
            <Link to={`/search/employees?id=${employee.employee.id}`}
                className="mr-2 align-items-center"
            >{employee.employee.first_name} {employee.employee.last_name}
            </Link>- {employee.evrole.name}
        </>
    );
};

const ProjectCard: React.FC<Props> = (props) => {
    const { project } = props;

    const {
        name,
        description,
        start_date,
        end_date,
        skill: skills,
        employee: employees,
    } = project;

    const softSkills = skills.filter(
        ({ skill }: { skill: any }) => skill && skill.category === 'Soft Skills',
    ).sort((a: any, b: any) => b.required_rating - a.required_rating);

    const otherSkills = skills.filter(
        ({ skill }: { skill: any }) => skill && skill.category !== 'Soft Skills',
    ).sort((a: any, b: any) => b.required_rating - a.required_rating);

    const team = _.uniqWith(
        employees.sort((a: any, b: any) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime()),
        (a: any, b: any) => {
            if (a && a.employee && b && b.employee) {
                return a.employee?.id === b.employee?.id;
            }
            return false;
        }
    );

    const renderProjectInfo = (): JSX.Element => {
        return (
            <Col className="mb-5">
                <Row>
                    <Col xs="12" lg="6" className="pr-lg-4">
                        <Row>
                            <Col xs="auto" className={styles.iconCol}>
                                <FaRegFileCode size="20" />
                            </Col>
                            <Col className={styles.infoCol}>
                                <Row className={`mb-2 ${styles.title}`}>
                                    <Col>
                                        {name}
                                    </Col>
                                </Row>
                                <Row className={'mb-4'} >
                                    <Col className={styles.info}>{description || 'No project description.'}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" lg="6">
                        <Row>
                            <Col xs="auto" className={styles.iconCol}>
                                <FaRegCalendarAlt size="20" />
                            </Col>
                            <Col className={styles.infoCol}>
                                <Row className={`mb-4 my-1 ${styles.durationRow}`}>
                                    <Col className={styles.info}>
                                        {start_date ? <Moment format="ll">{start_date}</Moment> : 'N/A'}
                                        {' - '}
                                        {end_date ? <Moment format="ll">{end_date}</Moment> : 'N/A'}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    };

    const renderSkills = (title: string, skills: any, noSkillText: string, Icon: IconType): JSX.Element => {
        return (
            <Col className="mb-5 pr-lg-4" xs="12" lg="6">
                <Row>
                    <Col xs="auto" className={styles.iconCol}>
                        <Icon size="20" />
                    </Col>
                    <Col className={styles.infoCol}>
                        <Row className={`mb-3 ${styles.title}`}>
                            <Col>
                                {title}
                            </Col>
                        </Row>
                        <Row className={`mt-1 mb-4 ${styles.skillsRow}`}>
                            <Col className={styles.skillChipsCol}>
                                {skills.length === 0 && <span>{noSkillText}</span>}
                                {skills.length > 0 && skills.map((skill: any) => {
                                    return (
                                        <SkillChip key={`otherSkill-${skill.id}`} label={skill.skill.name} rating={skill.required_rating} />
                                    );
                                })}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    };

    const renderTeam = (): JSX.Element => {
        return (
            <Col className="mb-5 pr-lg-4">
                <Row>
                    <Col xs="auto" className={styles.iconCol}>
                        <FaRegUserCircle size="20" />
                    </Col>
                    <Col className={styles.infoCol}>
                        <Row className={`mb-3 ${styles.title}`}>
                            <Col>
                                Team Members
                            </Col>
                        </Row>
                        {team.map((employee: any, index: number) => {
                            if (!employee.employee) {
                                return (<React.Fragment key={index} />);
                            }

                            return (
                                <span key={index}>
                                    {index > 0 && <span>, </span>}
                                    <EmployeeChip key={index} employee={employee} />
                                </span>
                            );
                        })}
                    </Col>
                </ Row>
            </Col>
        );
    };

    return (
        <Card className="p-4 p-sm-5 my-3">
            <Row>
                {renderProjectInfo()}
            </Row>
            <Row>
                {renderTeam()}
            </Row>
            <Row>
                {renderSkills('Technical Skills', otherSkills, 'No required technical skills.', FaCode)}
                {renderSkills('Soft Skills', softSkills, 'No required technical skills.', FaRegHandshake)}
            </Row>
        </Card>
    );
};



export default ProjectCard;