import { Button, Col, Row, Spinner } from 'react-bootstrap';
import React, { useEffect } from 'react';

import { IconType } from 'react-icons';
// eslint-disable-next-line
// @ts-ignore
import ReactStars from 'react-rating-stars-component';
import SkillChip from '../SkillChip/SkillChip';
import styles from './SkillRatingList.module.css';
import { useState } from 'react';

type Skill = {
    id: number;
    name: string;
};

interface SkillRating {
    skill: Skill;
    rating: number;
}

interface SkillRatingsProps {
    title: string;
    skillTitle: string;
    skillSource: Skill[];
    selectedSkillRatings?: SkillRating[];
    isLoading?: boolean;
    Icon?: IconType;
    onSkillRatingsChanged?: (skillRatings: SkillRating[]) => void;
}

const SkillRatings: React.FC<SkillRatingsProps> = (props: SkillRatingsProps) => {
    const { Icon } = props;

    const [skillRatings, setSkillRatings] = useState<SkillRating[]>([]);

    const selectedSkillIds = skillRatings.filter(x => x.skill.id).map(x => x.skill.id);
    const skillOptions = props.skillSource.filter(ss => !selectedSkillIds.includes(ss.id))
        .map((x: any) => ({
            value: x.id,
            label: x.name,
            ratingValue: 0,
        })).sort((a: any, b: any) => {
            return a.label > b.label && 1 || -1;
        });

    const handleOnClickRemove = (index: number) => {
        const newSkillRatings = [...skillRatings];
        newSkillRatings.splice(index, 1);
        setSkillRatings(newSkillRatings);

        if (props.onSkillRatingsChanged) {
            props.onSkillRatingsChanged(newSkillRatings);
        }
    };

    const onAddSkill = (skillOptions): void => {
        const newSkillRatings = [...skillRatings];
        skillOptions.forEach(skillOption => {
            if (skillOption.ratingValue > 0) {
                newSkillRatings.push(
                    {
                        skill: {
                            id: parseInt(skillOption.value),
                            name: skillOption.label
                        },
                        rating: skillOption.ratingValue * 2
                    });
            }
        });
        setSkillRatings(newSkillRatings);
        if (props.onSkillRatingsChanged) {
            props.onSkillRatingsChanged(newSkillRatings);
        }
    };

    useEffect(() => {
        if (props.selectedSkillRatings && props.selectedSkillRatings.length > 0) {
            setSkillRatings(props.selectedSkillRatings);
        }
    }, [props.selectedSkillRatings]);

    return (
        <div>
            <Row>
                <Col>
                    <h2 className={styles.title}>
                        {Icon && <>
                            <Icon size="23" className={styles.titleIcon} />&nbsp;&nbsp;
                        </>}
                        {props.title}
                    </h2>
                </Col>
                <Col className="text-right">
                    <p className={styles.itemCount}>
                        {skillRatings.length} {skillRatings.length === 1 ? 'item' : 'items'}
                    </p>
                </Col>
            </Row>
            {!props.isLoading && (
                <>
                    <div className={styles.skillRatingItems}>
                        <Row>
                            <Col className={styles.skillRatingItemsWrapper}>
                                {!props.isLoading && skillRatings.map((skillRating, index) => (
                                    <SkillChip
                                        key={index}
                                        label={skillRating.skill.name}
                                        rating={skillRating.rating}
                                        onDeleteButtonClick={(): void => handleOnClickRemove(index)}
                                    />
                                ))}
                            </Col>
                        </Row>
                    </div>
                </>
            )}
            <Row>
                <div className={`${styles.scroll}`}>
                    { skillOptions.map(
                        skillOption =>
                            <Col key={skillOption.value}>
                                <div>
                                    {skillOption.label}
                                </div>
                                <div className={styles.starsSection}>
                                    <ReactStars
                                        count={5}
                                        size={28}
                                        isHalf={true}
                                        activeColor="#f4b331"
                                        value={skillOption.ratingValue}
                                        key={skillOption.ratingValue}
                                        onChange={(value: any) => {
                                            skillOption.ratingValue = value;
                                            onAddSkill(skillOptions);
                                        }}
                                    />
                                </div>
                            </Col>
                    )}
                </div>
            </Row>
            {props.isLoading &&
                <Row>
                    <Col className="text-center my-5">
                        <Spinner animation="border" className="me-5 text-center" />
                    </Col>
                </Row>
            }
        </div>
    );
};

export default SkillRatings;