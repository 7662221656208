import React from 'react';
import { Col, 
    Row, 
} from 'react-bootstrap';
import styles from './NoSearchResult.module.css';
interface NoSearchResultProps {
    imageDisplay: any;
    textDisplay: string;
}
const NoSearchResult: React.FC<NoSearchResultProps> = ({imageDisplay, textDisplay}: NoSearchResultProps) => { 
    return (
        <Row>
            <Col className="text-center">
                <img src= {imageDisplay} className="img-fluid w-25 mt-5 mb-4"></img>
                <div className={`pt-0 font-weight-bold ${styles.displayLineBreak}`}>
                    {textDisplay}
                </div>
            </Col>
        </Row>
    );
};
export default NoSearchResult;