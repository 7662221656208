import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import querystring from 'querystring';
import {
    Col,
    Row,
    Accordion,
    Card
} from 'react-bootstrap';

// Services
import { searchEmployeeById, getAllEmployee } from '../../services/EmployeeService';

// Components
import SelectSearch from '../../components/Search/SelectSearch';
import EmployeeCard from '../../components/EmployeeCard/EmployeeCard';
import NoSearchResult from '../../components/NoSearchResult/NoSearchResult';
import SkillLegend from '../../components/SkillLegend/SkillLegend';

// Styles
import styles from './SearchPage.module.css';

//Assets
import noKeyword from '../../assets/images/no-keyword.png';


const NO_KEYWORD_TEXT = 'Nothing here yet! they may be busy? \n But if you do a search, you may find someone available';

const Empty = ({ entity } : { entity: string }) => {

    return (
        <Card>
            <Card.Header className="text-center">
            No {entity} Found
            </Card.Header>
        </Card>
    );
};

interface LabelValue {
    label: string;
    value: string;
}

const EmployeeSearchPage: React.FC = () => {
    const history = useHistory();
    const [keywords, setKeywords] = useState<LabelValue[]>([]);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [employeeOptions, setEmployeeOptions] = useState<any[]>([]);
    const keywordLabels = keywords.map(x => x.label);

    const submitForm = (newKeywords = keywords): void => {
        history.push({
            pathname: '/search/employees',
            search: querystring.stringify({ id: newKeywords.map(x => x.value) })
        });
    };

    const handleKeywordChange = (newValue: any): void => {
        setKeywords(newValue);

        submitForm(newValue);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        submitForm();
    };

    const fetchEmployeesOptions = async () => {
        const employee = await getAllEmployee();
        const options: any[] = [];
        employee.forEach((element: any) => {
            options.push({
                label: `${element.first_name} ${element.last_name}`,
                value: `${element.id}`
            });
        });

        setEmployeeOptions(options);
    };

    const renderEmployeeDetails = (employee: any): JSX.Element => {
        return <EmployeeCard employee={employee} noLegend={true} />;
    };

    const renderSearchEmployeeResults = (): JSX.Element => {
        if (!searchResults) {
            return (<React.Fragment></React.Fragment>);
        }

        const result = searchResults.map((r: any, index: number) => {
            const fullName = `${r.first_name} ${r.last_name}`;
            const location = r.location?.name;

            const level = r.employee_level.sort((a: any, b: any) => {
                return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
            }).slice(-1).pop();

            return (
                <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                        <Row key={`r-${index.toString()}`} className={'mb-2'}>
                            <Col>{fullName}</Col>
                            <Col className='text-center'>{location || ''}</Col>
                            <Col className='text-center'>{level?.level.name || ''}</Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index.toString()} className={`px-3 ${styles.employeeDetailContainer}`}>
                        {renderEmployeeDetails(r)}
                    </Accordion.Collapse>
                </Card>
            );
        });

        return (
            <>
                <Accordion defaultActiveKey='0'>
                    <Accordion.Toggle as={Card.Header} eventKey={'table-header'} className={`mb-1 ${styles.cardHeader}`} bsPrefix="cardHeader">
                        <Row>
                            <Col className={`${styles.tableHeader}`}>Name</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Location</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Level</Col>
                        </Row>
                    </Accordion.Toggle>
                    {searchResults.length > 0 ? result : <Empty entity="Employees"/>}
                </Accordion>
            </>
        );
    };

    useEffect(() => {
        const query = querystring.parse(history.location.search.replace('?', ''));
        const employeeIds: string[] = [];

        if (query.id) {
            if (Array.isArray(query.id)) {
                employeeIds.push(...query.id);

            } else {
                employeeIds.push(query.id);
            }
        }

        setKeywords(employeeOptions.filter(x => employeeIds.includes(x.value)));

        if (employeeOptions.length > 0 && employeeIds.length > 0) {
            const initiateSearch = async (): Promise<void> => {
                const results = await searchEmployeeById(employeeIds);
                setSearchResults(results);
            };

            initiateSearch();
        } else {
            setSearchResults([]);
        }
    }, [history.location, employeeOptions]);

    useEffect(() => {
        fetchEmployeesOptions();
    }, []);

    return (
        <>
            <Row className={'mt-4'}>
                <Col>
                    <SelectSearch
                        label={'EMPLOYEE: '}
                        options={employeeOptions}
                        value={keywords}
                        isMulti={true}
                        onChange={handleKeywordChange}
                        onSubmit={handleSubmit}
                        nonCreatable
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    {keywords.length > 0 && (
                        searchResults.length > 0 ? 
                            <h5 className="font-weight-bold mb-2">{searchResults.length} <label>Search Result for</label> &quot;{keywordLabels.join(', ')}&quot;</h5> : 
                            null
                    )}
                </Col>
                <Col xs="12" md="auto">
                    {searchResults.length > 0 ? <SkillLegend /> : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    {searchResults.length > 0 ? 
                        <div className="mb-4">{renderSearchEmployeeResults()}</div> : 
                        <NoSearchResult imageDisplay={noKeyword} textDisplay={NO_KEYWORD_TEXT} />
                    }
                </Col>
            </Row>
        </>
    );
};

export default EmployeeSearchPage;