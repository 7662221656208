import React from 'react';
import { 
    Container, 
    Row, 
    Col,    
    ToggleButton,
    ToggleButtonGroup} from 'react-bootstrap';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// Components
import Navigation from '../../components/NavMenu/NavMenu';
import EmployeeSearchPage from './EmployeeSearchPage';
import SkillSearchPage from './SkillSearchPage';
import ProjectSearchPage from './ProjectSearchPage';


const SearchPage: React.FC = () => {
    const history = useHistory();

    const getCategory = () => {
        const segments = window.location.pathname.split('/');
        return segments[segments.length - 1];
    };

    const handleCategoryChange = (val: any) => {
        history.push(`/search/${val}`);
    };

    return (
        <>
            <Navigation />
            <Container>
                <div className="mt-5">
                    <Row>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <Row className="align-items-center">
                                <Col lg="3">
                                    <h5 className="mr-3 my-lg-auto">CATEGORY:</h5>
                                </Col>
                                <Col lg="9">
                                    <ToggleButtonGroup name="categories" defaultValue={getCategory} onChange={handleCategoryChange} className="d-flex">
                                        <ToggleButton variant="outline-dark" value="employees">EMPLOYEES</ToggleButton>
                                        <ToggleButton variant="outline-dark" value="skills">SKILLS</ToggleButton>
                                        <ToggleButton variant="outline-dark" value="projects">PROJECTS</ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Switch>
                    <Route exact path="/search">
                        <Redirect to="/search/employees" />
                    </Route>
                    <Route path="/search/employees">
                        <EmployeeSearchPage />
                    </Route>
                    <Route path="/search/skills">
                        <SkillSearchPage />
                    </Route>
                    <Route path="/search/projects">
                        <ProjectSearchPage />
                    </Route>
                </Switch>
            </Container>
        </>
    );
};

export default SearchPage;