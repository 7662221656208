import React, { useEffect } from 'react';
import { Button, Card, Container } from 'react-bootstrap';

// Styles
import styles from './LoginPage.module.css';

import { FcGoogle } from 'react-icons/fc';
import { useAuth } from '../../context/auth/Auth';
import { useHistory } from 'react-router-dom';

const LoginPage = (): JSX.Element => {
    const { isAuthenticated, currentUserProfile } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated()) {
            const employeeId = (currentUserProfile?.employeeData ? currentUserProfile?.employeeData?.id : 0);
            const redirectUrl = employeeId !== 0 ? `/search/employees?id=${employeeId}` : '/profile/edit';
            history.replace(redirectUrl);
        }
    }, [isAuthenticated]);
    return (
        <Container className={`d-flex flex-column justify-content-center align-items-center h-100 bg-gray ${styles.container}`}>
            <h2>Employee Skills Tracker</h2>
            <Card className="p-4 mt-2">
                <span className="mb-3">
                    Please use your Elephant Ventures email account to access the site
                </span>
                <Button variant="outline-dark" href="/api/connect/google">
                    <FcGoogle size="3rem" className="mr-3" />
                    Login with Google
                </Button>
            </Card>
        </Container>
    );
};

export default LoginPage;
