import React from 'react';
import { Row } from 'react-bootstrap';
import Availability from './Availability';

interface EmployeeAvailabilityProps {
    employee: any;
}

const EmployeeAvailability: React.FC<EmployeeAvailabilityProps> = (props) => {
    const { employee: { '10kft_data': tenkData } } = props;

    return <>
        {(!tenkData || tenkData.length === 0) ? (
            <>(No availability information)</>
        ) : (
            <Row>
                {tenkData
                    .sort((a: any, b: any) => a.work_week - b.work_week)
                    .map((data: any, index: number): JSX.Element => {
                        const { billable, non_billable } = data.allocation || {};

                        let availability: 'available' | 'not_available' | 'partially_available';

                        if (billable > 0.75) {
                            availability = 'not_available';
                        } else if (billable >= 0.25) {
                            availability = 'partially_available';
                        } else {
                            availability = 'available';
                        }

                        return <Availability
                            key={index}
                            week={data.work_week}
                            availability={availability}
                            className="mr-1 mb-1 col col-xs-12 col-xl-5"
                            billableAllocation={billable}
                            nonBillableAllocation={non_billable}
                            permalink={`https://app.10000ft.com/users/${data.user_id}/tracker`}
                        />;
                    })}
            </Row>
        )}
    </>;
};

export default EmployeeAvailability;