import React from 'react';
import { Card } from 'react-bootstrap';
import { BsFillStarFill, BsX } from 'react-icons/bs';
import RatingColors  from './RatingColors';
// eslint-disable-next-line
// @ts-ignore
import styles from './SkillChip.module.css';
interface SkillChipProps {
    label: string;
    rating: number;
    onDeleteButtonClick?: () => void;
}
const SkillChip: React.FC<SkillChipProps> = (props: SkillChipProps) => {
    const { label, rating, onDeleteButtonClick } = props;
    const ratingColor: any = RatingColors.find((r : any) => r.value === rating);
    const style: any = { backgroundColor: 'none', borderColor: ratingColor?.color };
    let deleteButton = <></>;
    if (onDeleteButtonClick) {
        deleteButton = <BsX onClick={onDeleteButtonClick} className={styles.close} />;
    }
    return (
        <Card
            className={`mr-1 mx-1 my-1 align-items-center flex-row ${styles.chip}`}
            style={style}
        >
            {label}
            <div className={styles.chipStar}>
                <div className={styles.chipStarCell}>
                    <BsFillStarFill className="mx-2 " color={ratingColor?.color} />
                    {rating / 2}
                    {deleteButton}
                </div>
            </div>
        </Card>
    );
};
export default SkillChip;