import moment from 'moment';
import React from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { FaRegCalendarAlt } from 'react-icons/fa';

import styles from './Availability.module.css';

interface AvailabilityProps {
    week: number;
    availability: 'available' | 'not_available' | 'partially_available';
    billableAllocation?: number;
    nonBillableAllocation?: number;
    permalink?: string;
    className?: string;
}

const Availability: React.FC<AvailabilityProps> = (props) => {
    const availabilityLabels = {
        'available': 'Available',
        'not_available': 'Not Available',
        'partially_available': 'Partially Available'
    };

    const containerClassName = `container-${props.availability.replace('_', '-')}`;

    const round = (num: number): number => {
        return Math.round((num + Number.EPSILON) * 100) / 100; // Reference: https://stackoverflow.com/a/11832950
    };

    const renderOverlay = (overlayProps: any): JSX.Element => {
        return <Popover body {...overlayProps}>
            <Popover.Content>
                {props.billableAllocation !== undefined &&
                    <div>Billable: {round(props.billableAllocation * 100)}%</div>
                }
                {props.nonBillableAllocation !== undefined &&
                    <div>Non-Billable: {round(props.nonBillableAllocation * 100)}%</div>
                }
                {props.billableAllocation !== undefined && props.nonBillableAllocation !== undefined &&
                    <div>Unallocated: {round((1 - props.billableAllocation - props.nonBillableAllocation) * 100)}%</div>
                }
            </Popover.Content>
        </Popover>;
    };

    const Content = <div
        className={`${styles.container} ${styles[containerClassName]} p-1 pr-2 ${props.className}`}
        onClick={() => props.permalink && (window.location.href = props.permalink)}
    >
        <Row className='align-items-center'>
            <Col xs="auto" className="pr-0">
                <FaRegCalendarAlt size="30" color='#ffffff' />
            </Col>
            <Col className="pl-2">
                {props.week === moment().week() &&
                    <Row>
                        <Col>
                            This Week
                        </Col>
                    </Row>
                }
                {props.week === moment().add(7, 'day').week() &&
                    <Row>
                        <Col>
                            Next Week
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        {availabilityLabels[props.availability]}
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>;

    if (props.billableAllocation !== undefined || props.nonBillableAllocation !== undefined || props.permalink) {
        return <OverlayTrigger
            placement="bottom"
            overlay={renderOverlay}
        >
            {Content}
        </OverlayTrigger>;
    } else {
        return Content;
    }
};

export default Availability;