import { Card, Col, Row } from 'react-bootstrap';
import { FaBookOpen, FaCode, FaRegFileCode, FaRegHandshake } from 'react-icons/fa';

import { CgProfile } from 'react-icons/cg';
import EmployeeAvailability from '../Availability/EmployeeAvailability';
import { IoMdRibbon } from 'react-icons/io';
import Moment from 'react-moment';
import React from 'react';
import SkillChip from '../SkillChip/SkillChip';
// Components
import SkillLegend from '../SkillLegend/SkillLegend';
//Style
import styles from './EmployeeCard.module.css';

interface Props {
    employee: any;
    noLegend?: boolean;
}

const EmployeeCard: React.FC<Props> = (props) => {
    const { employee, noLegend } = props;
    const {
        id: employeeId,
        first_name,
        last_name,
        employee_level,
        location,
        skill: skills,
        certification: certifications,
        project: projects,
        biography: biography,
    } = employee;

    const softSkills = skills.filter(
        ({ skill }: { skill: any }) => skill && skill.category === 'Soft Skills',
    ).sort((a: any, b: any) => b.rating - a.rating);

    const otherSkills = skills.filter(
        ({ skill }: { skill: any }) => skill && skill.category !== 'Soft Skills',
    ).sort((a: any, b: any) => b.rating - a.rating);

    const level = employee_level.sort((a: any, b: any) => {
        return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
    }).slice(-1).pop();

    const renderBasicInfo = (): JSX.Element => {
        return (
            <React.Fragment>
                <Row className={`mx-1 mb-1 ${styles.title}`}>
                    <CgProfile size='2em' /> <span>{first_name} {last_name}</span>
                </Row>
                <Row className={'mx-3 mb-3'} >
                    <Col className={styles.levelLeftMargin}>
                        {level.level?.name} - {location?.abbreviation}
                    </Col>
                </Row>
                <Row className={'mx-3 mb-3'} >
                    <Col className={styles.levelLeftMargin}>
                        <EmployeeAvailability employee={employee} />
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const renderBiography = (): JSX.Element => {
        return (
            <React.Fragment>
                {biography !== null &&
                    <React.Fragment>
                        <Row className={`mx-1 mb-1 ${styles.title}`}>
                            <FaBookOpen size='2em' /><span> Bio </span>
                        </Row>
                        <Row className={styles.bioContent}>
                            {biography}
                        </Row>
                    </React.Fragment>}
            </React.Fragment>
        );
    };

    const renderCertifications = (): JSX.Element => {
        return (
            <React.Fragment>
                <Row className={`mx-1 mb-3 mt-4 ${styles.title}`}>
                    <IoMdRibbon size='2em' /> <span>Certifications</span>
                </Row>
                <Row className={'mx-3 mb-3'}>
                    <Col className={`mb-1 ${styles.info} ${styles.levelLeftMargin}`}>
                        {certifications.length === 0 &&
                            'No certifications'
                        }
                        {certifications.length > 0 && certifications.map((certification: any, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <Row className={'mb-2'}>
                                        <Col className={`${styles.certName}`}>
                                            {certification.certification.name} - {certification.issue_date ? <Moment format="ll">{certification.issue_date}</Moment> : 'NA'}
                                            {certification.expiration_date &&
                                                <React.Fragment>{' to '}<Moment format="ll">{certification.expiration_date}</Moment></React.Fragment>
                                            }
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            );
                        })}
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const renderSkills = (icon: any, title: string, skills: any, noSkillText: string): JSX.Element => {
        return (
            <Col xs="12" xl="6" className="mb-3">
                <Row className={`mb-2 ${styles.title}`}>
                    <Col>
                        {icon} <span>{title}</span>
                    </Col>
                </Row>
                <Row className="ml-4 mt-1">
                    {skills.length === 0 && <Col>{noSkillText}</Col>}
                    <Col className={styles.skillChipsCol}>
                        {skills.length > 0 && skills.map((skill: any) => {
                            return (
                                <div key={`otherSkill-${skill.id}`} >
                                    <SkillChip label={skill.skill.name} rating={skill.rating} />
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </Col>
        );
    };

    const renderProjects = (): JSX.Element => {
        if (!projects || projects.length === 0) {
            return (<React.Fragment></React.Fragment>);
        }

        const formatProjects = (): any[] => {
            const formattedProjects: any[] = [];

            projects.forEach((project: any) => {
                const employeeProjectHistory = project.employee.filter((e: any) => {
                    return e.employee?.id === employeeId;
                })
                    .sort((a: any, b: any) => {
                        return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
                    });

                const otherTeamMembers = project.employee.filter((e: any) => e.employee?.id !== employeeId);

                employeeProjectHistory.forEach((eph: any) => {
                    formattedProjects.push({
                        ...project,
                        employee: [...otherTeamMembers, eph]
                    });
                });
            });

            return formattedProjects;
        };

        const formattedProjects = formatProjects();


        return (
            <Col className={styles.projectContainer}>
                {formattedProjects.length > 0 && (
                    <Row className={`mx-3 mb-2 ${styles.title}`}>
                        <FaRegFileCode size='2em' />
                        <span>
                            Projects
                        </span>
                    </Row>
                )}
                <Row xs={12} md={12} lg={12} className={`${styles.projectList}`}>
                    {formattedProjects.map((project: any, index: number) => {
                        const employee = project.employee.filter((e: any) => e.employee?.id === employeeId)
                            .sort((a: any, b: any) => {
                                return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
                            }).slice(-1).pop();

                        return (

                            <div key={index} className={`ml-2 px-2 py-2 ${styles.projectCardContent}`}>
                                <Row className={'ml-0 mr-3 mt-1'} >
                                    <Col className={styles.projectTitle}>{project.name}</Col>
                                </Row>
                                <Row className={'ml-0 mr-3 mt-0.5'} >
                                    <Col className={styles.description}>{project.description || 'NA'}</Col>
                                </Row>
                                <Row className={'ml-0 mr-3 mt-0.5'} >
                                    <Col className={styles.role}>{employee.evrole?.name}</Col>
                                </Row>
                                <Row className={'ml-0 mr-3 mt-0.5'}>
                                    <Col className={styles.duration}>
                                        <span>
                                            {employee.start_date ? <Moment format="MM/DD/YYYY">{employee.start_date}</Moment> : 'NA'}
                                            {' - '}
                                            {employee.end_date ? <Moment format="MM/DD/YYYY">{employee.end_date}</Moment> : 'NA'}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </Row>
            </Col>
        );
    };

    return (
        <>
            <Card className="p-2 p-sm-4 my-3">
                <Row>
                    <Col xs="12" md="12">
                        {renderBasicInfo()}
                        {renderBiography()}
                        {renderCertifications()}
                    </Col>
                    <Col xs="12" md="12">
                        {(noLegend) ? '' :
                            <Row className="mb-3 mx-1">
                                <SkillLegend />
                            </Row>
                        }
                        <Row>
                            {renderSkills(<FaCode size='2em' />, 'Technical Skills', otherSkills, 'No technical skills.')}
                            {renderSkills(<FaRegHandshake size='2em' />, 'Soft Skills', softSkills, 'No soft skills.')}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {renderProjects()}
                </Row>
            </Card>
        </>
    );
};

export default EmployeeCard;