import api from '../utils/api';
import utils from '../utils/utils';
import { getAuthHeaders } from '../context/auth/Auth';

export const searchProject = async (keywords?: string[]): Promise<any> => {
    const query = !keywords ? '' : keywords
        .map((x: string) => `_where[_or][0][name_contains]=${x}&_where[_or][1][description_contains]=${x}`)
        .join('&');

    return await api.get(`/projects?${query}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const getAllProject = async (): Promise<any> => {
    return await api.get('/projects?_limit=-1',
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const searchProjectBySkills = async (keywords?: string[], pageSize = 10, pageIndex = 0): Promise<any> => {
    const query = {
        filter: keywords?.length === 0 ? undefined : keywords,
        _limit: pageSize,
        _start: pageSize * pageIndex,
    };

    const qs = utils.stringifyQuery(query);

    return await api.get(`/projects/skill?${qs}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const searchProjectBySkillsCount = async (keywords?: string[]): Promise<any> => {
    const query = !keywords ? '' : keywords
        .map((x: string) => `filter=${x}`)
        .join('&');

    return await api.get(`/projects/skill/count?${query}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const updateProjectEmployeesById = async (
    id: string | number,
    employeeId: string | number,
    body: Record<string, unknown>,
): Promise<any> => {
    return await api.put(
        `/projects/${id}/employees/${employeeId}`,
        body,
        { headers: { ...getAuthHeaders() } }
    ).then((response) => response.data);
};