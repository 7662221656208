import querystring from 'querystring';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const stringifyQuery = (query: any, omitUndefined = true): string => {
    if (omitUndefined) {
        return querystring.stringify(JSON.parse(JSON.stringify(query)));
    } else {
        return querystring.stringify(query);
    }
};

const getPages = (pageSize: number, totalCount: number): number => {
    let pages = Math.floor(totalCount / pageSize);
    if ((totalCount / pageSize) % 1 > 0) {
        pages++;
    }

    return pages;
};

export default {
    stringifyQuery,
    getPages,
};