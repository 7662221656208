import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useAuth } from '../context/auth/Auth';
import { STORAGE_KEY_REDIRECT_PATH } from '../constants/storageKeys';

type PrivateRouteProps = RouteProps

const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...props }: PrivateRouteProps): JSX.Element => {
    const auth = useAuth();

    if (!auth.isAuthenticated()) {
        if ((props as any).computedMatch?.url) {
            localStorage.setItem(STORAGE_KEY_REDIRECT_PATH, (props as any).computedMatch?.url);
        }

        return <Route path={props.path}
            render={(): JSX.Element => <Redirect to={'/login' + window.location.search} />} />;
    }

    return <Route {...props} />;
};

export default PrivateRoute;
