import React, { useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import styles from './Certification.module.css';
import Moment from 'react-moment';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { FaAward } from 'react-icons/fa';
import CertificationEdit from './CertificationEdit';

type CertificationListProps = {
    title: string;
    certifications: any;
    certificationList: any;
    isLoading?: boolean;
    onDeleteCertification: (certification: any) => void
    onEditCertification: (certification: any) => void
}

const CertificationList: React.FC<CertificationListProps> = (props: CertificationListProps) => {
    const { title, certifications, certificationList, isLoading, onEditCertification, onDeleteCertification } = props;

    const [edit, setEdit] = useState<number>();

    const onEdit = (certification: any) => {
        onEditCertification(certification);
        setEdit(undefined);
    };

    const onDelete = (certification: any) => {
        const toDelete = confirm('Are you sure?');
        if (toDelete) {
            onDeleteCertification(certification);
            setEdit(undefined);
        }
    };

    if (!certificationList || certificationList.length === 0) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <React.Fragment>
            <Row className={`mb-3 mt-5 ${styles.title}`}>
                <Col>
                    <FaAward size="23" />&nbsp;&nbsp;{title}
                </Col>
            </Row>
            {!isLoading && certificationList.map((cert: any, index: number) => {
                return (
                    <div key={index} className={`p-3 mb-3 ${styles.certContainer}`}>
                        {edit !== index ? (
                            <Row>
                                <Col>
                                    <Row className={`mb-2 ${styles.certTitle}`} >
                                        <Col>{cert.certification.name}</Col>
                                    </Row>
                                    <Row className={`mb-1 ${styles.date}`} >
                                        <Col>
                                            {cert.issue_date &&
                                                <span>
                                                    Issue Date:  <Moment format="ll">{cert.issue_date}</Moment>
                                                </span>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={`mb-1 ${styles.date}`} >
                                        <Col>
                                            {cert.expiration_date &&
                                                <span>
                                                    Expiration Date:<Moment format="ll">{cert.expiration_date}</Moment>
                                                </span>
                                            }
                                        </Col>
                                    </Row>
                                    {cert.link &&
                                        <Row className={styles.link} >
                                            <Col>
                                                <a href={cert.link}>Certification link</a>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                <Col xs="12" sm="auto" className="d-flex justify-content-end align-items-center">
                                    <Button variant="link" onClick={() => setEdit(index)}>
                                        <BsPencil size="20" color="black" />
                                    </Button>
                                    <Button variant="link" onClick={() => onDelete(cert)}>
                                        <BsTrash size="20" color="black" />
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <CertificationEdit
                                certifications={certifications}
                                certification={cert}
                                onEditCertification={onEdit}
                                selectedCertification={certificationList}
                            />
                        )}
                    </div>
                );
            })}
            {isLoading &&
                <Col className="text-center my-5">
                    <Spinner animation="border" className={`text-center ${styles.saveSpinner}`} />
                </Col>
            }
        </React.Fragment>
    );
};


export default CertificationList;