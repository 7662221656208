
import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';

// Services
import { getEmployeesWithProjects } from '../../services/EmployeeService';
import { useAuth } from '../../context/auth/Auth';

// Components
import ProjectList from '../../components/Project/ProjectList';

// Styles
import styles from './ProfileEditPage.module.css';
import ProjectInput from '../../components/Project/ProjectInput';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { updateProjectEmployeesById } from '../../services/ProjectService';


type ProjectEditProps = {
    projects: any;
    roles: any;
}

const ProjectEdit: React.FC<ProjectEditProps> = (props: ProjectEditProps) => {
    const history = useHistory();
    const { currentUserProfile, fetchCurrentUserEmployeeData } = useAuth();
    const { projects, roles } = props;
    const employeeId = (currentUserProfile.employeeData ? currentUserProfile.employeeData.id : 0);
    const [loading, setLoading] = useState(false);
    const [employeeProjects, setEmployeeProjects] = useState<any[]>([]);
    const [saveableProjects, setSaveableProjects] = useState<any[]>([]);

    const fetchEmployeeProfile = async (): Promise<void> => {
        const employees = await getEmployeesWithProjects([employeeId]);
        if (!employees || employees.length === 0) {
            return;
        }
        const employee = employees[0];
        const employeeProjects = employee && employee.project || [];
        setEmployeeProjects(employeeProjects);
    };

    const onDeleteProject = (project: any, employee: any): void => {
        if (project.isNew) {
            const filteredProjects = saveableProjects.filter((item: any) => item.local_id !== project.local_id);
            setSaveableProjects(filteredProjects);
        } else {
            const projectIndex = employeeProjects.findIndex((item: any) => item.id === project.id);
            const filteredEmployees = employeeProjects[projectIndex].employee.filter((item: any) => item.id !== employee.id);
            employeeProjects[projectIndex].employee = filteredEmployees;

            setEmployeeProjects([
                ...employeeProjects,
            ]);
        }
    };

    const onEditProject = (project: any, originalProj: any, originalEmployeeObj: any): void => {
        const employeeProjectIndex = employeeProjects.findIndex(
            (item: any) => item?.employee.length > 0 && isEqual(item?.employee, originalProj.employee),
        );
        const saveableProjectIndex = saveableProjects.findIndex(
            (item: any) => {
                return item?.employee.length > 0 && isEqual(item?.employee, originalProj.employee);
            }
        );
        if (employeeProjectIndex !== -1) {
            const editedEmployeeProjects = [
                ...employeeProjects,
            ];
            if (project.id === originalProj.id) {
                editedEmployeeProjects[employeeProjectIndex] = project;
                setEmployeeProjects(editedEmployeeProjects);
            } else {
                // Delete Existing Employee from Array and put to delete
                const employeeProject = employeeProjects[employeeProjectIndex];
                const employeeIndex = employeeProject.employee.findIndex((item: any) => isEqual(item, originalEmployeeObj));
                if (employeeIndex !== -1) {
                    employeeProject.employee.splice(employeeIndex, 1);
                }
                editedEmployeeProjects[employeeProjectIndex] = employeeProject;
                setEmployeeProjects(editedEmployeeProjects);
                setSaveableProjects([
                    ...saveableProjects,
                    project,
                ]);
            }
        }
        if (saveableProjectIndex !== -1) {
            const editedEmployeeProjects = [
                ...saveableProjects,
            ];
            editedEmployeeProjects[saveableProjectIndex] = project;
            setSaveableProjects(editedEmployeeProjects);
        }
    };

    const onSaveProject = (project: any) => {
        setSaveableProjects([
            ...saveableProjects,
            project,
        ]);
    };

    const saveProjectChanges = async () => {
        const projectIds = new Set<any>();
        allProjects.forEach((item: any) => projectIds.add(item.id));

        const projectsToSave: any[] = [];
        Array.from(projectIds).forEach((item: number) => {
            const project = projects.find((obj: any) => obj.id === item);
            if (project) {
                projectsToSave.push({ id: project.id, employee: project.employee });
            }
        });
        projectsToSave.forEach((item: any) => {
            const modifiedOriginal = employeeProjects.find((obj) => obj.id === item.id);
            if (modifiedOriginal) {
                item.employee = modifiedOriginal.employee;
            }
            saveableProjects.forEach((obj) => {
                if (obj.id === item.id) {
                    item.employee = [
                        ...item.employee,
                        ...obj.employee,
                    ];
                }
            });
        });

        for (const { id, employee } of projectsToSave) {
            await updateProjectEmployeesById(id, employeeId, { employee });
        }
    };

    const onButtonBackClicked = async (): Promise<void> => {
        history.push('/profile/edit/skills-and-certifications');
    };

    const onButtonSaveClicked = async (): Promise<void> => {
        setLoading(true);
        await saveProjectChanges();
        await fetchCurrentUserEmployeeData();
        setLoading(false);
        history.push(`/search/employees?id=${employeeId}`);
    };

    useEffect(() => {
        fetchEmployeeProfile();
    }, []);

    const allProjects = [
        ...employeeProjects,
        ...saveableProjects,
    ];

    return (
        <React.Fragment>
            <ProjectList
                title='Project Information'
                projects={projects}
                roles={roles}
                currentProjects={allProjects}
                employeeId={employeeId}
                onDelete={onDeleteProject}
                onEdit={onEditProject}
            />
            <ProjectInput
                projects={projects}
                roles={roles}
                currentProjects={allProjects}
                employeeId={employeeId}
                onSave={onSaveProject}
            />
            <Container>
                <Row>
                    <Col className="text-center mt-5 mb-5">
                        <Button
                            onClick={onButtonBackClicked}
                            variant="outline-dark"
                            className="px-5  mx-3"
                            disabled={loading}
                        >
                            {loading && <Spinner animation="border" size="sm" className="mr-3" />}
                            Back
                        </Button>
                        <Button
                            onClick={onButtonSaveClicked}
                            className={`px-5 mx-3 ${styles.nextButton}`}
                            disabled={loading}
                        >
                            {loading && <Spinner animation="border" size="sm" className="mr-3" />}
                            Save
                        </Button>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ProjectEdit;