import api from '../utils/api';
import { getAuthHeaders } from '../context/auth/Auth';
import utils from '../utils/utils';

export const getEmployeeByUserId = async (id: string | number): Promise<any> => {
    return await api.get(`/employees?users_permissions_user=${id}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const createEmployee = async (body: Record<string, unknown>): Promise<any> => {
    return await api.post('/employees',
        body,
        { headers: { ...getAuthHeaders() } }
    ).then((response) => response.data);
};

export const updateEmployeeByUserId = async (
    id: string | number,
    body: Record<string, unknown>,
): Promise<any> => {
    return await api.put(
        `/employees/${id}`,
        body,
        { headers: { ...getAuthHeaders() } }
    ).then((response) => response.data);
};

export const searchEmployeeById = async (ids: string[]): Promise<any> => {
    const query = utils.stringifyQuery({
        id: ids
    });

    return await api.get(`/employees?${query}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const getAllEmployee = async (): Promise<any> => {
    return await api.get('/employees?_limit=-1',
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const searchEmployeeBySkills = async (keywords?: string[], pageSize = 10, pageIndex = 0): Promise<any> => {
    const query = {
        filter: keywords?.length === 0 ? undefined : keywords,
        _limit: pageSize,
        _start: pageSize * pageIndex,
    };

    const qs = utils.stringifyQuery(query);

    return await api.get(`/employees/skill?${qs}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};

export const searchEmployeeBySkillsCount = async (keywords?: string[]): Promise<any> => {
    const query = !keywords ? '' : keywords
        .map((x: string) => `filter=${x}`)
        .join('&');

    return await api.get(`/employees/skill/count?${query}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};


export const getEmployeesWithProjects = async (ids: string[] | number[]): Promise<any> => {
    const query = utils.stringifyQuery({
        id: ids
    });

    return await api.get(`/employees/project?${query}`,
        { headers: { ...getAuthHeaders() }, params: {} }
    ).then((response) => response.data);
};
