import api from '../utils/api';
import { getAuthHeaders } from '../context/auth/Auth';

export const getAllSkills = async (): Promise<any> => {
    return await api.get('/skills?_limit=-1',
        { headers: { ...getAuthHeaders() }, params: {}}
    ).then((response) => {
        const employee = response.data;
        const cache = {};
        const options: any[] = [];
        employee.forEach((element: any) => {
            if (cache[element.name] === undefined) {
                cache[element.name] = 1;
            } else {
                cache[element.name] ++;
            }
        });
        employee.forEach((element: any) => {
            let name = element.name;
            if (cache[element.name] > 1) {
                element.name = name = element.name +` (${element.category})`;
            }
            options.push({
                label: name,
                value: name,
                category: `${element.category}`
            });
        });
        return employee;
    });
};