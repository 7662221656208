/* Styles */
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Col, Form, Row } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import React from 'react';
import Select from 'react-select';
/* Utilities */
import { isEqual } from 'lodash';
import moment from 'moment';
import styles from './Project.module.css';
import { useState } from 'react';

const fields = {
    project: false,
    role: false,
    dateOverlap: false,
    date: false,
};

const checkFieldErrors = (validationErrors: any) => {
    return Object.keys(validationErrors).some(
        (key) => validationErrors[key] === true,
    );
};

const checkIfDateOverlaps = (date: moment.Moment, start?: string, end?: string) => {
    if (start && end) {
        return date.isSameOrAfter(start, 'date') && date.isSameOrBefore(end, 'date');
    } else if (start && !end) {
        return date.isSameOrAfter(start, 'date');
    } else if (!start && end) {
        return date.isSameOrBefore(end, 'date');
    }
    return false;
};

interface ProjectInputProps {
    /**
     * Array of Projects existing in DB
     */
    projects: any
    /**
     * Array of Roles existing in DB
     */
    roles: any
    /**
     * Current Projects of the Employee being edited
     */
    currentProjects: any
    /**
     * ID of the Employee being edited
     */
    employeeId: number
    /**
     *
     */
    editMode?: boolean
    /**
     * Employee's Project Details to edit
     */
    project?: any
    /**
     * The array index of the project being edited
     */
    projectIndex?: number
    /**
     * The current employee record being edited
     */
    employee?: any
    onSave: (project: any) => void
    onCancel?: () => void
}

const ProjectInput: React.FC<ProjectInputProps> = (props) => {
    const {
        projects,
        roles,
        currentProjects,
        employeeId,
        onSave,
        onCancel,
        employee,
        projectIndex = -1,
        editMode = false,
    } = props;

    const project = projectIndex > -1 ? currentProjects[projectIndex] : null;

    const [selectedProject, selectProject] = useState<any>(
        project ? { label: project.name, value: project.id } : null
    );
    const [selectedRole, selectRole] = useState<any>(
        employee ? { label: employee.evrole?.name, value: employee.evrole?.id } : null
    );
    const [startDate, setStartDate] = useState<Date | null>(
        employee?.start_date ? moment(employee.start_date).toDate() : null
    );
    const [endDate, setEndDate] = useState<Date | null>(
        employee?.end_date ? moment(employee.end_date).toDate() : null
    );
    const [validationErrors, setValidationErrors] = useState<typeof fields>(fields);

    const validations = {
        validateProject: () => !!selectedProject && selectedProject.value !== -1,
        validateRole: () => !!selectedRole && selectedRole.value !== -1,
        validateDateOverlap: () => {
            if (!selectedProject) return true;
            const employeeProjects = currentProjects.filter(
                (project: any, index: number) => project?.id === selectedProject.value && index !== projectIndex,
            ).map(
                (project: any) => project.employee.filter((item: any) => item.employee?.id === employeeId),
            );
            if (employeeProjects.length > 0) {
                const parsedStartDate = startDate && moment(startDate);
                const parsedEndDate = endDate && moment(endDate);
                for (const employeeProject of employeeProjects) {
                    for (const { start_date, end_date } of employeeProject) {
                        if (
                            parsedStartDate && checkIfDateOverlaps(parsedStartDate, start_date, end_date) ||
                            parsedEndDate  && checkIfDateOverlaps(parsedEndDate, start_date, end_date)
                        ) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        validateDate: () => (startDate && endDate) ? !(moment(startDate).isSameOrAfter(endDate, 'date')) : true,
    };

    const handleSubmit = () => {
        const errors = {
            ...fields,
        };

        errors.project = !validations.validateProject();
        errors.role = !validations.validateRole();
        errors.dateOverlap = !validations.validateDateOverlap();
        errors.date = !validations.validateDate();

        if (checkFieldErrors(errors)) {
            setValidationErrors(errors);
            return;
        }
        const selectedProjectObj = projects.find((item: any) => item.id === selectedProject.value);
        if (editMode && selectedProjectObj.id === project.id) {
            const employeeIndex = project.employee.findIndex((item: any) => isEqual(item, employee));
            const editedProj = {
                ...project,
            };
            editedProj.employee[employeeIndex] = {
                ...editedProj.employee[employeeIndex],
                evrole: {
                    id: selectedRole.value,
                    name: selectedRole.label,
                },
                start_date: startDate && moment(startDate).toISOString(true),
                end_date: endDate && moment(endDate).toISOString(true),
            };
            onSave(editedProj);
        } else {
            const local_id = Date.now();
            onSave({
                ...selectedProjectObj,
                local_id,
                isNew: true,
                employee: [
                    {
                        employee: {
                            id: employeeId,
                        },
                        evrole: {
                            id: selectedRole.value,
                            name: selectedRole.label,
                        },
                        start_date: startDate && moment(startDate).toISOString(true),
                        end_date: endDate && moment(endDate).toISOString(true),
                    }
                ],
            });
        }
        setValidationErrors(errors);
        resetInputs();
    };

    const resetInputs = () => {
        selectProject(null);
        selectRole(null);
        setStartDate(null);
        setEndDate(null);
    };

    const projectNameOptions = projects.map((project: any) => ({
        label: project.name,
        value: project.id,
    })).sort((a: any, b: any) => {
        return a.label > b.label && 1 || -1;
    });

    const roleOptions = roles.map((role: any) => ({
        label: role.name,
        value: role.id,
    })).sort((a: any, b: any) => {
        return a.label > b.label && 1 || -1;
    });

    const selectedProjectDetails = selectedProject ? projects.find(
        (project: any) => project.id === selectedProject.value
    ) : null;

    return (
        <Row>
            <Col>
                {!editMode && <h1 className={`mb-3 mt-3 ${styles.title}`}>Add New Project to Profile</h1>}
                <p>You must click &ldquo;Add&rdquo; before &ldquo;Save&rdquo; in order to save projects to your profile!</p>
                <div>
                    <Form.Group controlId="name">
                        <Select
                            className="react-select"
                            options={projectNameOptions}
                            placeholder="Select Project"
                            value={selectedProject}
                            onChange={selectProject}
                        />
                        {validationErrors.project &&
                            <div  className={`invalid-feedback ${styles.errorMessage}`} >Project is Required</div>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            disabled
                            className={`${styles.textArea}`}
                            placeholder="Details"
                            value={selectedProjectDetails?.description || ''}
                        />
                    </Form.Group>
                    <Form.Group controlId="role">
                        <Select
                            className="react-select"
                            options={roleOptions}
                            placeholder="Select Role"
                            value={selectedRole}
                            onChange={selectRole}
                        />
                        {validationErrors.role &&
                            <div  className={`invalid-feedback ${styles.errorMessage}`} >Role is Required</div>
                        }
                    </Form.Group>
                    <Row>
                        <Col xs="12" sm="6" className="mb-0">
                            <Form.Group controlId="startDate" className={`${styles.newDateContainer}`}>
                                <DatePicker
                                    required
                                    className={`form-control ${styles.newDate}`}
                                    placeholderText='Start Date'
                                    selected={startDate}
                                    onChange={(date: any) => setStartDate(date)}/>
                                {(validationErrors.date || validationErrors.dateOverlap) && (
                                    <>
                                        {validationErrors.date &&
                                    <div  className={`invalid-feedback ${styles.errorMessage}`} >
                                        End Date must be greater than Start Date
                                    </div>
                                        }
                                        {validationErrors.dateOverlap &&
                                    <div  className={`invalid-feedback ${styles.errorMessage}`} >
                                        Dates must not be overlapping with the Same Projects
                                    </div>
                                        }
                                    </>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6" className="mb-0">
                            <Form.Group controlId="endDate" className={`${styles.newDateContainer}`}>
                                <DatePicker
                                    className={`form-control ${styles.newDate}`}
                                    placeholderText='End Date'
                                    selected={endDate}
                                    onChange={(date: any) => setEndDate(date)}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col className="text-left">
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            variant="outline-dark text-center"
                            className="px-5 mr-3 mb-1"
                        >
                            {editMode ? 'Save' : 'Add'}
                        </Button>
                        {editMode && onCancel && (
                            <Button
                                onClick={onCancel}
                                type="submit"
                                variant="outline-dark text-center"
                                className="px-5 mb-1"
                            >
                                Cancel
                            </Button>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ProjectInput;