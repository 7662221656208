import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styles from './Project.module.css';
import Moment from 'react-moment';
import { BsPencil, BsTrash } from 'react-icons/bs';
import {
    FaCode,
    FaRegFileCode,
    FaRegHandshake,
} from 'react-icons/fa';

import ProjectInput from './ProjectInput';
import SkillChip from '../SkillChip/SkillChip';

type ProjectListProps = {
    title: string;
    currentProjects: any[];
    employeeId: number;
    projects?: any[];
    roles?: any[];
    onEdit?: (project: any, originalProjIndex: number, originalEmployeeObj: any) => void
    onDelete?: (project: any, employee: any) => void,
}

const ProjectList: React.FC<ProjectListProps> = (props: ProjectListProps) => {
    const { title, currentProjects, projects = [], roles = [], employeeId, onEdit, onDelete } = props;
    const [edit, setEdit] = useState<number>();

    const editMode = projects.length > 0 && roles.length > 0 && !!onEdit && !!onDelete;

    const onEditProject = (project: any, originalProjIndex: number, originalEmployeeObj: any) => {
        setEdit(undefined);
        onEdit && onEdit(project, currentProjects[originalProjIndex], originalEmployeeObj);
    };

    const onDeleteProject = (projectIndex: any, employee: any) => {
        if (!onDelete) return;
        const answer = confirm('Are you sure?');
        answer && onDelete(currentProjects[projectIndex], employee);
    };

    if (!currentProjects || currentProjects.length === 0) {
        return (<React.Fragment></React.Fragment>);
    }

    const formatProjects = (): any[] => {
        const formattedProjects: any[] = [];

        currentProjects.forEach((project: any) => {
            const employeeProjectHistory = project.employee.filter((e: any) => {
                return e.employee?.id === employeeId;
            })
                .sort((a: any, b: any) => {
                    return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
                });

            const otherTeamMembers = project.employee.filter((e: any) => e.employee?.id !== employeeId);

            employeeProjectHistory.forEach((eph: any) => {
                formattedProjects.push({
                    ...project,
                    employee: [...otherTeamMembers, eph]
                });
            });
        });

        return formattedProjects;
    };

    const formattedProjects = formatProjects();

    const renderSkills = (skills: any): any => {
        if (!skills || skills.length === 0) {
            return (<React.Fragment>N/A</React.Fragment>);
        }

        return (
            <Row className={`pl-2 ${styles.skills}`}>
                {skills.map((skill: any) => {
                    return (<SkillChip key={`skill-${skill.id}`} label={skill.name} rating={skill.rating} />);
                })}
            </Row>
        );
    };

    const renderListItem = (project: any, index: number): JSX.Element => {
        const originalProjectIndex = currentProjects.findIndex(
            (item) => project.isNew ? project.local_id === item.local_id : project.id === item.id,
        );
        const employee = project.employee.filter((e: any) => e.employee?.id === employeeId)
            .sort((a: any, b: any) => {
                return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
            }).slice(-1).pop();

        const skills = !project.skill ? [] :
            project.skill
                .filter((s: any) => s.skill.category !== 'Soft Skill')
                .map((s: any) => ({ id: s.id, name: s.skill.name, rating: s.required_rating }));

        const softSkills = !project.skill ? [] :
            project.skill
                .filter((s: any) => s.skill.category === 'Soft Skill')
                .map((s: any) => ({ id: s.id, name: s.skill.name, rating: s.required_rating }));

        return (
            <div key={index} className={`py-3 px-1 mb-4 ${styles.projectContainer}`}>
                {edit !== index ? (
                    <Row>
                        <Col className={styles.projectDetailsCol}>
                            <Row className={'mx-0 mt-2 mb-4'}>
                                <Col xs="auto" className={styles.iconCol}>
                                    <FaRegFileCode size="32" className={styles.icon} />
                                </Col>
                                <Col className={styles.projectBasicInfoCol}>
                                    <h3 className={styles.projectHeading}>{project.name}</h3>
                                    <div className={`mb-2 ${styles.description}`}>{project.description || 'NA'}</div>
                                    <div className={`mb-2 ${styles.role}`}>{employee.evrole?.name}</div>
                                    <div className={styles.duration}>
                                        <span>
                                            {employee.start_date ? <Moment format="ll">{employee.start_date}</Moment> : 'NA'}
                                            {' - '}
                                            {employee.end_date ? <Moment format="ll">{employee.end_date}</Moment> : 'NA'}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'mx-0 mt-2 mb-4'}>
                                <Col xs="auto" className={styles.iconCol}>
                                    <FaCode size="32" className={styles.icon} />
                                </Col>
                                <Col>
                                    <h3 className={styles.projectHeading}>Technical Skills</h3>
                                    {renderSkills(skills)}
                                </Col>
                            </Row>
                            <Row className={'mx-0 mt-2 mb-1'}>
                                <Col xs="auto" className={styles.iconCol}>
                                    <FaRegHandshake size="32" className={styles.icon} />
                                </Col>
                                <Col>
                                    <h3 className={styles.projectHeading}>Soft Skills</h3>
                                    {renderSkills(softSkills)}
                                </Col>
                            </Row>
                        </Col>
                        {editMode &&
                            <Col xs="12" sm="auto" className="d-flex justify-content-end align-items-center col-auto mt-2 mt-sm-0">
                                <Button variant="link" onClick={() => setEdit(index)}>
                                    <BsPencil color="black" />
                                </Button>
                                <Button variant="link" onClick={() => onDeleteProject(originalProjectIndex, employee)}>
                                    <BsTrash color="black" />
                                </Button>
                            </Col>
                        }
                    </Row>
                ) : (
                    <div className="px-3">
                        <ProjectInput
                            editMode={true}
                            projects={projects}
                            roles={roles}
                            currentProjects={currentProjects}
                            employeeId={employeeId}
                            project={project}
                            employee={employee}
                            projectIndex={originalProjectIndex}
                            onSave={(item: any) => onEditProject(item, originalProjectIndex, employee)}
                            onCancel={() => setEdit(undefined)}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            {formattedProjects.length > 0 && (
                <Row className={`mb-4 ${styles.title}`}>
                    <Col>
                        <FaRegFileCode size="32" className={styles.icon} />&nbsp;&nbsp;{title}
                    </Col>
                </Row>
            )}
            {formattedProjects.map((project: any, index: number) => {
                return renderListItem(project, index);
            })}
        </React.Fragment>
    );
};


export default ProjectList;