import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from './Auth';
import { STORAGE_KEY_REDIRECT_PATH } from '../../constants/storageKeys';

const TIMER_INTERVAL = 1000 * 60 * 1;

const AuthTimer: React.FC = (): JSX.Element => {
    const auth = useAuth();
    const history = useHistory();
    let timer: any = null;

    const startTimer = () => {
        if (timer) {
            clearInterval(timer);
        }

        timer = setInterval(() => {
            const url = window.location.pathname + (window.location.search ? `${window.location.search}`: '');

            if (!auth.isAuthenticated()) {
                if (window.location.pathname !== '/login' && window.location.pathname !== 'google-auth') {
                    localStorage.setItem(STORAGE_KEY_REDIRECT_PATH, url);
                }
                auth.logout();
                clearInterval(timer);
                timer = null;
                history.push('/login');
            }
        }, TIMER_INTERVAL);

    };

    useEffect(() => {
        startTimer();

        return () => {
            if (timer) {
                clearInterval(timer);
            }
            timer = null;
        };
    }, []);


    return (<React.Fragment/>);
};

export default AuthTimer;