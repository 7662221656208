import React, { FormEvent } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { Form, Row, Col,  } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

type SelectSearchProps = {
    label: string;
    value: any;
    options?: any[];
    onChange: (newValue: any) => void;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    isMulti: boolean;
    MenuComponent?: React.FC;
    nonCreatable?: boolean;
}

const SearchIndicator: React.FC = () => {
    return (
        <div className="mx-2 d-flex">
            <BsSearch />
        </div>
    );
};

const colors = {
    black: '#343a40',
    white: '#fff',
};

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        borderColor: colors.black,
        borderRadius: 4,
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: colors.black,
        borderRadius: 4,
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: colors.white,
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: colors.white,
        ':hover': {
            backgroundColor: colors.white,
            color: colors.black,
        },
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        color: colors.black,
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        backgroundColor: colors.black,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        ':hover': {
            backgroundColor: colors.black,
            color: colors.white,
        },
        backgroundColor: state.isFocused ? colors.black : provided.backgroundColor,
        color: state.isFocused ? colors.white : provided.color,
    }),
};

const SelectSearch: React.FC<SelectSearchProps> = (props: SelectSearchProps) => {
    const {
        label,
        value,
        options,
        onChange,
        onSubmit,
        isMulti,
        MenuComponent,
        nonCreatable
    } = props;

    const selectComponents = {
        DropdownIndicator: SearchIndicator,
        Menu: MenuComponent
    };

    if (!MenuComponent) {
        delete selectComponents['Menu'];
    }

    const sortedOptions = options?.sort((a: any, b: any) => {return a.label > b.label && 1 || -1;});

    const selectProps = {
        options: sortedOptions,
        isMulti,
        components: selectComponents,
        onChange: onChange,
        value: value,
        styles: customStyles,
    };

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                    <Form.Group as={Row}>
                        <Form.Label column lg="3">
                            <h5>{label}</h5>
                        </Form.Label>
                        <Col lg="9">
                            {nonCreatable ? (
                                <Select { ...selectProps } />
                            ) : (
                                <CreatableSelect { ...selectProps } />
                            )}
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};

export default SelectSearch;