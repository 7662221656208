import React from 'react';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../context/auth/Auth';
import { STORAGE_KEY_REDIRECT_PATH } from '../../constants/storageKeys';

const GoogleAuth: React.FC = () => {
    const { login } = useAuth();
    const search = useLocation().search;
    const history = useHistory();

    const performLogin = async(query : any) => {
        const userProfile = await login(query);
        let redirectURL = localStorage.getItem(STORAGE_KEY_REDIRECT_PATH);

        if (redirectURL?.startsWith('/login') || redirectURL?.startsWith('/google-auth')) {
            redirectURL = null;
        }

        if (redirectURL) {
            localStorage.removeItem(STORAGE_KEY_REDIRECT_PATH);
            history.replace(redirectURL);
            return;
        }

        if (userProfile && userProfile.employeeData) { 
            history.replace(`/search/employees?id=${userProfile.employeeData.id}`);
            return;
        }

        history.replace('/profile/edit');
    };

    useEffect(() => {
        performLogin(search);
    }, [search]);


    return  (
        <Container>
        </Container>
    );
};

export default GoogleAuth;