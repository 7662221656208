import React, {useState} from 'react';
import { Row, Col, FormControl, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Certification.module.css';
import moment from 'moment';

type CertificationEditProps = {
    certifications: any;
    certification: any;
    selectedCertification: any;
    onEditCertification: (certification: any) => void;
}

const CertificationInput: React.FC<CertificationEditProps> = (props) => {
    const { certification, onEditCertification } = props;
    const [selectedCert, setSelectedCert] = useState({value: certification.certification.id, label: certification.certification.name });
    const [startDate, setStartDate] = useState<Date | null>(moment(certification.issue_date).toDate());
    const [endDate, setEndDate] = useState<Date | null>(certification.expiration_date ? moment(certification.expiration_date).toDate(): null);
    const [link, setLink] = useState(certification.link);
    const [error, setError] = useState<any>({selectedCert: false, startDate: false, endDate: false});

    const setDefaultState = () => {
        setSelectedCert({value: -1, label: ''});
        setStartDate(null);
        setEndDate(null);
        setLink('');
        setError({selectedCert: false, startDate: false, endDate: false});
    };

    const startDateChange = (date: any) => {
        setStartDate(date);
    };

    const endDateChange = (date: any) => {
        setEndDate(date);
    };

    const onSelectedCertChange = (cert: any) => {
        setSelectedCert(cert);
    };

    const onLinkChange = (event: any) => {
        setLink(event.target.value);
    };

    const onEditButtonClick = () => {
        const error = {selectedCert: false, startDate: false, endDate: false};
        if (!selectedCert || selectedCert.value === -1 ) {
            error.selectedCert = true;
        }

        if (!startDate) {
            error.startDate = true;
        }

        if ((endDate && startDate) && (endDate < startDate)) {
            error.endDate = true;
        }

        if (error.selectedCert || error.startDate || error.endDate) {
            setError(error);
            return;
        }

        onEditCertification(
            {
                id: certification.id,
                certification: { id: selectedCert.value, name: selectedCert.label},
                issue_date: startDate?.toISOString(),
                expiration_date: endDate ? endDate.toISOString() : null,
                link: link
            }
        );

        setDefaultState();

    };

    const options = props.certifications.filter((c: any) => {
        const isSelected = props.selectedCertification.find((s: any) => s.certification.id === c.id && s.certification.id !== certification.certification.id);
        return !isSelected;
    }).map((c: any) => {
        return ({value: c.id, label: c.name});
    }).sort((a: any, b: any) => {
        return a.label > b.label && 1 || -1;
    });

    return (
        <Row>
            <Col>
                <div className="mx-3">
                    <Form.Group controlId="certification">
                        <Select className={'react-select'}
                            options={options}
                            placeholder="Select certification"
                            value={selectedCert}
                            onChange={onSelectedCertChange}
                        />
                        {(error && error.selectedCert) &&
                            <div  className={`invalid-feedback ${styles.errorMessage}`} >Certification is Required</div>
                        }
                    </Form.Group>
                    <Form.Group controlId="link">
                        <FormControl
                            type="input"
                            placeholder="Certification Link"
                            value={link}
                            onChange={onLinkChange}
                        />
                    </Form.Group>
                    <Row className={'mb-2'}>
                        <Col xs="12" sm="6">
                            <Form.Group controlId="issueDate" className={`${styles.newDateContainer}`}>
                                <DatePicker
                                    required
                                    className={`form-control ${styles.newDate}`}
                                    placeholderText='Issue Date'
                                    selected={startDate}
                                    onChange={startDateChange}
                                />
                                {(error && error.startDate) &&
                                <div  className={`invalid-feedback ${styles.errorMessage}`} >Issue Date is Required</div>
                                }
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group controlId="expiryDate" className={`${styles.newDateContainer}`}>
                                <DatePicker 
                                    className={`form-control ${styles.newDate}`} 
                                    placeholderText='Expiration Date'
                                    selected={endDate} 
                                    onChange={endDateChange}/>
                                {(error && error.endDate) && 
                                <div  className={`invalid-feedback ${styles.errorMessage}`} >Expiration Date is must be greater than Issue date</div>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col className="text-left mb-3">
                        <Button
                            onClick={onEditButtonClick}
                            type="submit"
                            variant="outline-dark text-center"
                            className="px-5  mx-3"
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default CertificationInput;
