import React from 'react';
import {
    BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { AuthProvider, AuthConsumer } from './context/auth/Auth';
import AuthTimer from './context/auth/AuthTimer';
import PrivateRoute from './hocs/PrivateRoute';
import LoginPage from './pages/Login/LoginPage';
import GoogleAuth from './pages/GoogleAuth/GoogleAuth';
// import HomePage from './pages/Home/HomePage';
import ProfileEditPage from './pages/Profile/ProfileEditPage';
import SearchPage from './pages/Search/SearchPage';

const routes = {
    loggedIn: [
        {
            isPrivate: true,
            path: '/profile/edit',
            exact: false,
            component: ProfileEditPage,
        },
        {
            isPrivate: true,
            path: '/search',
            exact: false,
            component: SearchPage,
        },
        // {
        //     isPrivate: true,
        //     path: '/',
        //     exact: true,
        //     component: HomePage
        // },
    ],
    loggedOut: [
        {
            isPrivate: false,
            path: '/google-auth',
            exact: true,
            component: GoogleAuth,
        }
    ]
};

export default function App(): JSX.Element {

    const renderMainRoutes = (auth: any) => {
        const { isAuthenticated, currentUserProfile } = auth;
        const { loggedIn, loggedOut } = routes;
        const employeeId = (currentUserProfile?.employeeData ? currentUserProfile?.employeeData?.id : 0);
        const redirectUrl =
            isAuthenticated() ?
                employeeId !== 0 ?
                    `/search/employees?id=${employeeId}` :
                    '/profile/edit' :
                '/login';

        const listOfRoutes = isAuthenticated() ? loggedIn : loggedOut;
        return (
            <Switch>
                {listOfRoutes.map((route: any, index: number) => {
                    const { isPrivate, ...routeProps } = route;
                    return isPrivate ? <PrivateRoute {...routeProps} key={index}/> : <Route {...routeProps} key={index}/>;
                })}
                <Route exact path="/login" component={LoginPage}/>
                <Redirect to={redirectUrl} />
            </Switch>
        );
    };

    return (
        <AuthProvider>
            <AuthConsumer>
                {(auth: any): JSX.Element => (
                    <Router>
                        { auth.isAuthenticated() && <AuthTimer/>}
                        {renderMainRoutes(auth)}
                    </Router>
                )}
            </AuthConsumer>
        </AuthProvider>
    );
}
