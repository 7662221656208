import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import querystring from 'querystring';
import {
    Col,
    Row,
    Accordion,
    Card,
    Pagination
} from 'react-bootstrap';
import _ from 'lodash';
// eslint-disable-next-line
// @ts-ignore
import ReactStars from 'react-rating-stars-component';
import Moment from 'react-moment';

// Services
import { searchEmployeeBySkills, searchEmployeeBySkillsCount } from '../../services/EmployeeService';
import { searchProjectBySkills, searchProjectBySkillsCount } from '../../services/ProjectService';
import { getAllSkills } from '../../services/SkillService';
import utils from '../../utils/utils';

// Components
import SelectSearch from '../../components/Search/SelectSearch';
import EmployeeCard from '../../components/EmployeeCard/EmployeeCard';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import NoSearchResult from '../../components/NoSearchResult/NoSearchResult';

// Styles
import styles from './SearchPage.module.css';

//Assets
import noKeyword from '../../assets/images/no-keyword.png';
import noResult from '../../assets/images/no-result.png';

const NO_KEYWORD_TEXT = 'Nothing here yet! they may be busy? \n But if you do a search, you may find someone available';
const NO_RESULT_TEXT = 'Oh crap! We\'ve got nothing for you!';

const SkillSearchPage: React.FC = () => {
    const defaultPage = 1;
    const defaultPageSize = 10;
    const history = useHistory();
    const [skillsOptions, setSkillsOptions] = useState<any[]>([]);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [currentPageSize, setCurrentPageSize] = useState<number>(defaultPageSize);

    const [searchEmployeeResults, setSearchEmployeeResults] = useState<any[]>([]);
    const [searchEmployeeResultsTotalCount, setSearchEmployeeResultsTotalCount] = useState<number>(0);
    const [currentEmployeePage, setCurrentEmployeePage] = useState<number>(defaultPage);

    const [searchProjectResults, setSearchProjectResults] = useState<any[]>([]);
    const [searchProjectResultsTotalCount, setSearchProjectResultsTotalCount] = useState<number>(0);
    const [currentProjectPage, setCurrentProjectPage] = useState<number>(defaultPage);

    const employeePages = utils.getPages(currentPageSize, searchEmployeeResultsTotalCount);
    const projectPages = utils.getPages(currentPageSize, searchProjectResultsTotalCount);

    const submitForm = (newKeywords = keywords): void => {
        const query = {
            keywords: newKeywords,
            pageSize: currentPageSize === defaultPageSize ? undefined : currentPageSize  
        };

        history.push({
            pathname: '/search/skills',
            search: utils.stringifyQuery(query)
        });
    };

    const handleKeywordChange = (newValue: any): void => {
        const newKeywords = newValue.map((x: any) => x.value);
        setKeywords(newKeywords);
        submitForm(newKeywords);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        submitForm();
    };

    const fetchSkillsOptions = async () => {
        const employee = await getAllSkills();
        const options: any[] = [];
        employee.forEach((element: any) => {
            const name = element.name;
            options.push({
                label: name,
                value: name,
                category: `${element.category}`
            });
        });
        setSkillsOptions(options);
    };

    const redirectToPage = (page: number, isEmployee = true) => {
        const query = querystring.parse(location.search.replace('?', ''));
        if (isEmployee) {
            query.employeePage = page.toString();
        } else {
            query.projectPage = page.toString();
        }
        
        history.push({
            pathname: '/search/skills',
            search: querystring.stringify(query)
        });
    };

    useEffect(() => {
        const query = querystring.parse(history.location.search.replace('?', ''));

        const queryPageSize = Array.isArray(query.pageSize) ? query.pageSize[0] : query.pageSize;
        const pageSize = isNaN(parseInt(queryPageSize)) ? defaultPageSize : parseInt(queryPageSize);
        setCurrentPageSize(pageSize);

        const queryEmployeePage = Array.isArray(query.employeePage) ? query.employeePage[0] : query.employeePage;
        const employeePage = isNaN(parseInt(queryEmployeePage)) ? 1 : parseInt(queryEmployeePage);
        const employeePageIndex = employeePage - 1;
        setCurrentEmployeePage(employeePage);

        const queryProjectPage = Array.isArray(query.projectPage) ? query.projectPage[0] : query.projectPage;
        const projectPage = isNaN(parseInt(queryProjectPage)) ? 1 : parseInt(queryProjectPage);
        const projectPageIndex = projectPage - 1;
        setCurrentProjectPage(projectPage);

        const parsedKeywords: string[] = [];

        if (query.keywords) {
            if (Array.isArray(query.keywords)) {
                parsedKeywords.push(...query.keywords);

            } else {
                parsedKeywords.push(query.keywords);
            }
        }

        setKeywords(parsedKeywords);

        const initiateSearch = async (): Promise<void> => {
            const employeeResults = await searchEmployeeBySkills(parsedKeywords, pageSize, employeePageIndex);
            const employeeTotalCount = await searchEmployeeBySkillsCount(parsedKeywords);
            const projectResults = await searchProjectBySkills(parsedKeywords, pageSize, projectPageIndex);
            const projectTotalCount = await searchProjectBySkillsCount(parsedKeywords);

            setSearchEmployeeResults(employeeResults);
            setSearchEmployeeResultsTotalCount(employeeTotalCount);
            setSearchProjectResults(projectResults);
            setSearchProjectResultsTotalCount(projectTotalCount);
        };

        if (parsedKeywords.length === 0) {
            setSearchEmployeeResults([]);
            setSearchEmployeeResultsTotalCount(0);
            setSearchProjectResults([]);
            setSearchProjectResultsTotalCount(0);
        } else {
            initiateSearch();
        }
    }, [history.location]);

    useEffect(() => {
        fetchSkillsOptions();
    }, []);

    const renderStar = (rating: number): JSX.Element => {
        return (<ReactStars
            className={'justify-content-center'}
            count={5}
            size={12}
            isHalf={true}
            activeColor="#f4b331"
            key={rating / 2}
            value={rating / 2}
            edit={false}
        />);
    };

    const renderEmployeeDetails = (employee: any): JSX.Element => {
        return <EmployeeCard employee={employee} />;
    };

    const renderSearchEmployeeResults = (): JSX.Element => {
        if (!searchEmployeeResults || searchEmployeeResults.length === 0) {
            return (<React.Fragment></React.Fragment>);
        }

        const result = searchEmployeeResults.map((r: any, index: number) => {
            const fullName = `${r.first_name} ${r.last_name}`;
            const location = r.location?.name;

            const level = r.employee_level.sort((a: any, b: any) => {
                return (a.start_date < b.start_date) ? -1 : ((a.start_date > b.start_date) ? 1 : 0);
            }).slice(-1).pop();

            const matchSkills = r.skill.filter((s: any) => {
                const isMatch = keywords.find((k: any) => {
                    return !!s.skill.name.toLowerCase().includes(k.toLowerCase());
                });
                return !!isMatch;
            }).map((s: any) => {
                return ({
                    id: s.id,
                    name: s.skill.name,
                    rating: renderStar(s.rating),
                    note: s.note
                }
                );
            });

            return (
                <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                        {matchSkills &&
                            matchSkills.map((skill: any, skillIndex: number) => {
                                return (
                                    <Row key={`${index}-${skillIndex}`} className={'mb-2'}>
                                        <Col>{(skillIndex === 0 ? fullName : '')}</Col>
                                        <Col className='text-center'>{(skillIndex === 0 ? location : '')}</Col>
                                        <Col className='text-center'>{(skillIndex === 0 ? level?.level.name : '')}</Col>
                                        <Col className='text-center'>{skill.name}</Col>
                                        <Col className={styles.starContainer}>{skill.rating}</Col>
                                    </Row>
                                );
                            })
                        }

                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index.toString()} className={`px-3 ${styles.employeeDetailContainer}`}>
                        {renderEmployeeDetails(r)}
                    </Accordion.Collapse>
                </Card>
            );
        });

        return (
            <>
                <Accordion>
                    <Accordion.Toggle as={Card.Header} eventKey={'table-header'} className={`mb-1 ${styles.cardHeader}`} bsPrefix="cardHeader">
                        <Row>
                            <Col className={`${styles.tableHeader}`}>Name</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Location</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Level</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Skills</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Rating</Col>
                        </Row>
                    </Accordion.Toggle>
                    {result}
                </Accordion>
                <Row className="py-2">
                    <Col />
                    <Col xs="auto">
                        <Pagination>
                            {_.range(employeePages).map((i : any) => (
                                <Pagination.Item
                                    key={i}
                                    active={currentEmployeePage === i + 1}
                                    onClick={() => redirectToPage(i + 1)}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Col>
                </Row>
            </>
        );
    };

    const renderProjectDetails = (project: any): JSX.Element => {
        return <ProjectCard project={project}/>;
    };

    const renderSearchProjectResults = (): JSX.Element => {
        if (!searchProjectResults || searchProjectResults.length === 0) {
            return (<React.Fragment></React.Fragment>);
        }

        const result = searchProjectResults.map((r: any, index: number) => {
            const projectName = r.name;

            const matchSkills = r.skill.filter((s: any) => {
                const isMatch = keywords.find((k: any) => {
                    return !!s.skill.name.toLowerCase().includes(k.toLowerCase());
                });
                return !!isMatch;
            }).map((s: any) => {
                return ({
                    id: s.id,
                    name: s.skill.name,
                    rating: renderStar(s.required_rating),
                    note: s.note
                }
                );
            });

            return (
                <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                        {matchSkills &&
                            matchSkills.map((skill: any, skillIndex: number) => {
                                return (
                                    <Row key={`${index}-${skillIndex}`} className={'mb-2'}>
                                        <Col className={styles.projectTitle} title={(skillIndex === 0 ? projectName : '')}>{(skillIndex === 0 ? projectName : '')}</Col>
                                        <Col className='text-center'>
                                            {
                                                (skillIndex === 0) && 
                                                <span> 
                                                    { r.start_date ? <Moment format="ll">{r.start_date}</Moment> : 'NA'}
                                                    {' - '}
                                                    { r.end_date ? <Moment format="ll">{r.end_date}</Moment> : 'NA'}
                                                </span>
                                            }
                                        </Col>
                                        <Col className='text-center'></Col>
                                        <Col className='text-center'>{skill.name}</Col>
                                        <Col className={styles.starContainer}>{skill.rating}</Col>
                                    </Row>
                                );
                            })
                        }

                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index.toString()} className={`px-3 ${styles.projectDetailContainer}`}>
                        {renderProjectDetails(r)}
                    </Accordion.Collapse>
                </Card>
            );
        });

        return (
            <>
                <Accordion>
                    <Accordion.Toggle as={Card.Header} eventKey={'table-header'} className={`mb-1 ${styles.cardHeader}`} bsPrefix="cardHeader">
                        <Row>
                            <Col className={`${styles.tableHeader}`}>Project Name</Col>
                            <Col className={`col-2 text-center ${styles.tableHeader}`}>Duration</Col>
                            <Col className={`text-center ${styles.tableHeader}`}></Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Skills</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Rating</Col>
                        </Row>
                    </Accordion.Toggle>
                    {result}
                </Accordion>
                <Row className="py-2">
                    <Col />
                    <Col xs="auto">
                        <Pagination>
                            {_.range(projectPages).map((i : any) => (
                                <Pagination.Item
                                    key={i}
                                    active={currentProjectPage === i + 1}
                                    onClick={() => redirectToPage(i + 1, false)}
                                >
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <React.Fragment>
            <Row className={'mt-4'}>
                <Col>
                    <SelectSearch
                        label={'KEYWORD: '}
                        options={skillsOptions}
                        value={keywords.map((x: string) => ({ label: x, value: x }))}
                        isMulti={true}
                        onChange={handleKeywordChange}
                        onSubmit={handleSubmit}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {renderSearchEmployeeResults()}
                </Col>
            </Row>
            <Row>
                <Col>
                    {renderSearchProjectResults()}
                </Col>
            </Row>
            <Row>
                <Col>
                    {keywords.length > 0 ?
                        ((searchEmployeeResults.length === 0 && searchProjectResults.length === 0) && 
                        <NoSearchResult imageDisplay={noResult} textDisplay={NO_RESULT_TEXT} />) :
                        <NoSearchResult imageDisplay={noKeyword} textDisplay={NO_KEYWORD_TEXT} />
                    }    
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SkillSearchPage;