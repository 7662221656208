import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import {
    FaCode,
    FaRegHandshake,
} from 'react-icons/fa';
import React, { useEffect, useState } from 'react';

import CertificationInput from '../../components/Certification/CertificationInput';
import CertificationList from '../../components/Certification/CertificationList';
import SkillRatingList from '../../components/SkillRatings/SkillRatingList';
// Styles
import styles from './ProfileEditPage.module.css';
import { updateEmployeeByUserId } from '../../services/EmployeeService';
import { useAuth } from '../../context/auth/Auth';
import { useHistory } from 'react-router-dom';

type SkillsCertificationsEditProps = {
    certifications: any;
    skills: any;
}

const SkillsCertificationsEdit: React.FC<SkillsCertificationsEditProps> = (props: SkillsCertificationsEditProps) => {
    const history = useHistory();
    const { currentUserProfile, fetchCurrentUserEmployeeData } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [employeeTechnicalSkillRatings, setEmployeeTechnicalSkillRatings] = useState<any[]>([]);
    const [employeeSoftSkillRatings, setEmployeeSoftSkillRatings] = useState<any[]>([]);
    const [employeeCertificationChanges, setEmployeeCertificationChanges] = useState<any[]>([]);

    const employeeId = (currentUserProfile.employeeData ? currentUserProfile.employeeData.id : 0);
    const employeeCertification = (currentUserProfile.employeeData ? currentUserProfile.employeeData.certification : []);

    const { certifications, skills } = props;

    const technicalSkills = skills.filter((x: any) => x.category.toLowerCase() !== 'soft skills');
    const softSkills = skills.filter((x: any) => x.category.toLowerCase() === 'soft skills');

    const onDeleteCertification = (certification: any): void => {
        if (certification.id) {
            setEmployeeCertificationChanges([
                ...employeeCertificationChanges.filter((item) => item.id !== certification.id),
                { ...certification, isDeleted: true }
            ]);
        } else {
            setEmployeeCertificationChanges(
                employeeCertificationChanges.filter((cert) => cert.certification.id !== certification.certification.id)
            );
        }
    };

    const onAddCertification = (certification: any): void => {
        setEmployeeCertificationChanges([
            ...employeeCertificationChanges,
            certification
        ]);
    };

    const onEditCertification = (certification: any): void => {
        setEmployeeCertificationChanges([
            ...employeeCertificationChanges.filter((item) => item.id !== certification.id),
            certification
        ]);
    };

    const getCacheEmployeeCertification = (): any => {
        const newCert: any[] = [];

        employeeCertification.forEach((element: any) => {
            const isCertDeleted = employeeCertificationChanges.find(q => q.id === element.id && q.isDeleted);
            const editedCert = employeeCertificationChanges.find(q => q.id === element.id && !q.isDeleted);

            if (!isCertDeleted && !editedCert) {
                newCert.push(element);
            } else if (!isCertDeleted) {
                newCert.push(editedCert);
            }
        });


        employeeCertificationChanges.filter(q => !q.isDeleted && !newCert.find(s => s.id === q.id)).forEach(q => {
            newCert.push(q);
        });

        return newCert;
    };

    const onTechnicalSkillRatingsChange = (newSkillRatings: any) => {
        setEmployeeTechnicalSkillRatings(newSkillRatings);
    };

    const onSoftSkillRatingsChange = (newSkillRatings: any) => {
        setEmployeeSoftSkillRatings(newSkillRatings);
    };

    const saveCertificationChanges = async (): Promise<void> => {
        const cache = getCacheEmployeeCertification();
        const formattedCache = cache.map((c: any) => {
            c.certification = c.certification.id;
            return c;
        });

        setEmployeeCertificationChanges([]);
        await updateEmployeeByUserId(employeeId, { certification: formattedCache });
        await fetchCurrentUserEmployeeData();
    };

    const saveSkillRatingChanges = async (): Promise<void> => {
        const mapToSkillRatingsForUpdate = (skillRatings: any) => {
            return skillRatings.filter((x: any) => x.skill.id)
                .map((x: any) => ({
                    rating: x.rating,
                    skill: { id: x.skill.id }
                }));
        };
        const combinedSkillRatings = [
            ...mapToSkillRatingsForUpdate(employeeSoftSkillRatings),
            ...mapToSkillRatingsForUpdate(employeeTechnicalSkillRatings)
        ];

        await updateEmployeeByUserId(employeeId, { skill: combinedSkillRatings });
        await fetchCurrentUserEmployeeData();
    };

    const onButtonBackClicked = async (): Promise<void> => {
        await saveCertificationChanges();
        await saveSkillRatingChanges();
        history.push('/profile/edit/personal-information');
    };

    const onButtonSaveClicked = async (): Promise<void> => {
        setIsLoading(true);
        await saveCertificationChanges();
        await saveSkillRatingChanges();
        setIsLoading(false);
        history.push('/profile/edit/projects');
    };

    const cacheCert = getCacheEmployeeCertification();

    useEffect(() => {
        fetchCurrentUserEmployeeData();
    }, []);

    useEffect(() => {
        if (currentUserProfile && currentUserProfile.employeeData) {
            const employeeTechnicalSkills = currentUserProfile.employeeData.skill
                .filter((x: any) => x.skill.category.toLowerCase() !== 'soft skills');
            const employeeSoftSkills = currentUserProfile.employeeData.skill
                .filter((x: any) => x.skill.category.toLowerCase() === 'soft skills');

            const mapToSkillRating = (employeeSkills: any) => {
                return employeeSkills.map((x: any) => ({
                    skill: {
                        id: x.skill.id,
                        name: x.skill.name
                    },
                    rating: x.rating
                }));
            };
            setEmployeeTechnicalSkillRatings(mapToSkillRating(employeeTechnicalSkills));
            setEmployeeSoftSkillRatings(mapToSkillRating(employeeSoftSkills));
        } else {
            setEmployeeTechnicalSkillRatings([]);
            setEmployeeSoftSkillRatings([]);
        }
    }, [currentUserProfile]);

    return (
        <React.Fragment>
            <Row>
                <Col lg="6" className="mb-5">
                    <SkillRatingList
                        title="Skills"
                        skillTitle="Skills"
                        selectedSkillRatings={employeeTechnicalSkillRatings}
                        skillSource={technicalSkills}
                        onSkillRatingsChanged={onTechnicalSkillRatingsChange}
                        isLoading={isLoading}
                        Icon={FaCode}
                    />
                </Col>
                <Col lg="6" className="mb-5">
                    <SkillRatingList
                        title="Soft Skills"
                        skillTitle="Soft Skills"
                        selectedSkillRatings={employeeSoftSkillRatings}
                        skillSource={softSkills}
                        onSkillRatingsChanged={onSoftSkillRatingsChange}
                        isLoading={isLoading}
                        Icon={FaRegHandshake}
                    />
                </Col>
            </Row>
            <CertificationList
                title='Certifications'
                certifications={certifications}
                certificationList={cacheCert}
                onEditCertification={onEditCertification}
                onDeleteCertification={onDeleteCertification}
                isLoading={isLoading}
            />
            <CertificationInput
                title='Add Certifications'
                certifications={certifications}
                selectedCertification={cacheCert}
                onDeleteCertification={onDeleteCertification}
                onAddCertification={onAddCertification}
            />
            <Container>
                <Row>
                    <Col className="text-center mt-5 mb-5">
                        <Button
                            onClick={onButtonBackClicked}
                            variant="outline-dark"
                            className="px-5  mx-3"
                            disabled={isLoading}
                        >
                            {isLoading && <Spinner animation="border" size="sm" className="mr-3" />}
                            Back
                        </Button>
                        <Button
                            onClick={onButtonSaveClicked}
                            className={`px-5 mx-3 ${styles.nextButton}`}
                            disabled={isLoading}
                        >
                            {isLoading && <Spinner animation="border" size="sm" className="mr-3" />}
                            Next
                        </Button>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default SkillsCertificationsEdit;