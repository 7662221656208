import React, { useContext } from 'react';
import { getJWTToken } from '../../services/AuthService';
import { getEmployeeByUserId } from '../../services/EmployeeService';
import { 
    STORAGE_KEY_ACCESS_TOKEN,
    STORAGE_KEY_ACCESS_TOKEN_EXPIRY_DATE,
    STORAGE_KEY_CURRENT_USER_PROFILE
} from '../../constants/storageKeys';

type AuthContextProps = {
    login: (query: string) => any;
    logout: () => void;
    accessToken?: string | null;
    currentUserProfile?: any;
    clearLocalStorage: () => void;
    isAuthenticated: () => boolean;
    fetchCurrentUserEmployeeData: () => Promise<any>;
}

type AuthProviderState = {
}

interface AuthProviderProps {
    children?: JSX.Element;
}

const AuthContext = React.createContext<AuthContextProps>({
    login: () => null,
    logout: () => null,
    accessToken: null,
    currentUserProfile: null,
    clearLocalStorage: () => null,
    isAuthenticated: () => false,
    fetchCurrentUserEmployeeData: () => Promise.resolve(null),
});


const getAccessToken = (): string | null => {
    return localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN);
};

const getAccessTokenExpiryDate = (): Date | null => {
    const expiryDateString = localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN_EXPIRY_DATE);

    if (expiryDateString) {
        return new Date(expiryDateString);
    }

    return null;
};

const getCurrentUserProfile = (): any => {
    const userProfileString = localStorage.getItem(STORAGE_KEY_CURRENT_USER_PROFILE);

    if (userProfileString) {
        return JSON.parse(userProfileString);
    } else {
        return null;
    }
};

const getAuthHeaders = (): { Authorization: string }  => {
    return { Authorization: `Bearer ${getAccessToken()}` };
};


class AuthProvider extends React.Component<AuthProviderProps, AuthProviderState> {
    constructor(props: AuthProviderProps) {
        super(props);

        this.state = {
        };
    }

    componentDidMount(): void {
        this.fetchCurrentUserEmployeeData();
    }

    fetchCurrentUserEmployeeData = async (): Promise<any> => {
        const userProfile = getCurrentUserProfile();
        
        if (!userProfile) {
            return null;
        }

        const matchingEmployees = await getEmployeeByUserId(userProfile.userData.id);
        const employeeData = matchingEmployees && matchingEmployees.length > 0 ? matchingEmployees[0] : null;

        const newUserProfile = {
            ...userProfile,
            employeeData
        };

        localStorage.setItem(STORAGE_KEY_CURRENT_USER_PROFILE, JSON.stringify(newUserProfile));

        // Just to refresh the Auth context
        this.setState(this.state);

        return newUserProfile;
    }

    clearLocalStorage = (): void => {
        localStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
        localStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN_EXPIRY_DATE);
        localStorage.removeItem(STORAGE_KEY_CURRENT_USER_PROFILE);
    };

    login = async (query: string): Promise<any> => {
        const authData = await getJWTToken(query);

        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 1); // Token expiry is 1 day

        localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN_EXPIRY_DATE, expiryDate.toString());
        localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, authData.jwt);

        if (authData && authData.user) {
            const userProfile = {
                userData: authData.user
            };

            localStorage.setItem(STORAGE_KEY_CURRENT_USER_PROFILE, JSON.stringify(userProfile));

            return await this.fetchCurrentUserEmployeeData();
        }
        
        return authData;
    }

    logout = async (): Promise<void> => {
        this.clearLocalStorage();
    }

    isAuthenticated = (): boolean => {
        const accessToken = getAccessToken();
        const expiryDate = getAccessTokenExpiryDate();

        const isAuthValid = accessToken && expiryDate && expiryDate > new Date();

        if (!isAuthValid) {
            this.clearLocalStorage();
        }

        return !!isAuthValid;
    }

    render(): JSX.Element {
        return (
            <AuthContext.Provider
                value={{
                    login: this.login,
                    logout: this.logout,
                    accessToken: getAccessToken(),
                    currentUserProfile: getCurrentUserProfile(),
                    clearLocalStorage: this.clearLocalStorage,
                    isAuthenticated: this.isAuthenticated,
                    fetchCurrentUserEmployeeData: this.fetchCurrentUserEmployeeData,
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const AuthConsumer = AuthContext.Consumer;

const useAuth = (): AuthContextProps => useContext(AuthContext);

export {
    AuthContext,
    AuthProvider,
    AuthConsumer,
    useAuth,
    getAuthHeaders
};
