import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import React, { FormEvent, useEffect } from 'react';
import {
    createEmployee as createEmployeeAPI,
    getEmployeeByUserId,
    updateEmployeeByUserId
} from '../../services/EmployeeService';

import _ from 'lodash';
import { getLevels } from '../../services/LevelService';
import { getLocations } from '../../services/LocationService';
//Style
import styles from './ProfileEditPage.module.css';
import { useAuth } from '../../context/auth/Auth';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

const PersonalInformation: React.FC = () => {
    const { currentUserProfile, fetchCurrentUserEmployeeData } = useAuth();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [locations, setLocations] = useState<any[]>([]);
    const [levels, setLevels] = useState<any[]>([]);

    const { employeeData } = currentUserProfile;
    const employeeLevel = employeeData?.employee_level.length > 0
        ? _.orderBy(employeeData?.employee_level, ['start_date', 'id'], ['desc', 'desc'])[0]
        : undefined;
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        const formElements = (event.target as any).elements;
        if (form.checkValidity() && currentUserProfile) {
            setLoading(true);
            const { firstName, lastName, jobTitle, location, biography } = formElements;
            const user = await getEmployeeByUserId(currentUserProfile.userData.id);
            const body = {
                biography: biography.value,
                firstName: firstName.value,
                lastName: lastName.value,
                jobTitle: levels.find(
                    (item) => item.id.toString() === jobTitle.value,
                ),
                location: locations.find(
                    (item) => item.id.toString() === location.value,
                ),
            };

            if (!user || user.length === 0) {
                await createEmployee(body);
            } else {
                await updateEmployee(user[0].id, body);
            }
            setLoading(false);
            history.push('/profile/edit/skills-and-certifications');
            return;
        }
        setValidated(true);

    };

    const createEmployee = async (values: any) => {
        if (currentUserProfile) {
            const { firstName, lastName, jobTitle, location, biography } = values;
            await createEmployeeAPI({
                biography: biography,
                first_name: firstName,
                last_name: lastName,
                employee_level: [{
                    level: {
                        id: jobTitle?.id
                    },
                    start_date: (new Date()).toISOString()
                }],
                users_permissions_user: {
                    id: currentUserProfile?.userData?.id
                },
                location: location?.id
            });
        }
    };

    const updateEmployee = async (employee_id: number, values: any) => {
        const { firstName, lastName, jobTitle, location, biography } = values;
        const employeeLevels = employeeData.employee_level;

        if (jobTitle?.id !== employeeLevel?.level?.id) {
            employeeLevels.push({
                level: {
                    id: jobTitle?.id
                },
                start_date: (new Date()).toISOString()
            });
        }

        await updateEmployeeByUserId(
            employee_id,
            {
                biography: biography,
                first_name: firstName,
                last_name: lastName,
                employee_level: employeeLevels,
                location: location.id
            },
        );
    };

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await getLocations();
                setLocations(response.sort((a: any, b: any) => {return a.name > b.name && 1 || -1;}));
            } catch (error) {
                // TODO: Error Handling
            }
        };
        const fetchLevels = async () => {
            try {
                const response = await getLevels();
                setLevels(response.sort((a: any, b: any) => {return a.name > b.name && 1 || -1;}));
            } catch (error) {
                // TODO: Error Handling
            }
        };
        fetchCurrentUserEmployeeData();
        fetchLocations();
        fetchLevels();
    }, []);

    return (
        <Container>
            <Card className="p-3 p-sm-5 mt-5">
                <Form
                    id="personalInformationForm"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Row className="mb-3">
                        <Col xs="12" sm="6">
                            <Form.Group controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    defaultValue={employeeData?.first_name}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    First Name is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    defaultValue={employeeData?.last_name}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Last Name is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="6">
                            <Form.Group controlId="jobTitle">
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                >
                                    <option value="" disabled hidden>
                                        {levels.length > 0 ? 'Select Job Title' : 'Loading Job Titles'}
                                    </option>
                                    {levels.length > 0 && levels.map((item) => (
                                        <option
                                            key={`levels-${item.id}`}
                                            selected={item.id === employeeLevel?.level?.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Job Title is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                            <Form.Group controlId="location">
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    as="select"
                                    required
                                >
                                    <option value="" disabled hidden>
                                        {locations.length > 0 ? 'Select Location' : 'Loading Locations'}
                                    </option>
                                    {locations.length > 0 && locations.map((item) => (
                                        <option
                                            key={`location-${item.id}`}
                                            selected={item.id === employeeData?.location.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Location is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="biography">
                                <Form.Label>Biography</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    required
                                    defaultValue={employeeData?.biography}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Biography is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Row>
                <Col className="text-center mt-5 mb-5">
                    <Button
                        form="personalInformationForm"
                        type="submit"
                        disabled={loading}
                        className={`px-5 ${styles.nextButton}`}
                    >
                        {loading && <Spinner animation="border" size="sm" className="mr-3" />}
                        Next
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default PersonalInformation;