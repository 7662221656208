const RatingColors = [
    {value: 1, label: 'Beginner',  color: '#9CA3AF'},
    {value: 2, label: 'Beginner I',  color: '#FCA5A5'},
    {value: 3, label: 'Beginner II',  color: '#F87171'},
    {value: 4, label: 'Beginner III',  color: '#EF4444'},
    {value: 5, label: 'Intermediate I',  color: '#FEF3C7'},
    {value: 6, label: 'Intermediate II',  color: '#FDE68A'},
    {value: 7, label: 'Intermediate III',  color: '#FCD34D'},
    {value: 8, label: 'Expert I',  color: '#D1FAE5'},
    {value: 9, label: 'Expert II',  color: '#34D399'},
    {value: 10, label: 'Expert III',  color: '#10B981'}
];

export default RatingColors;
