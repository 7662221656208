import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import querystring from 'querystring';
import {
    Accordion,
    Card,
    Col,
    Row,
} from 'react-bootstrap';

// Services
import { searchProject, getAllProject } from '../../services/ProjectService';
import utils from '../../utils/utils';

// Components
import SelectSearch from '../../components/Search/SelectSearch';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import NoSearchResult from '../../components/NoSearchResult/NoSearchResult';

// Assets
import noKeyword from '../../assets/images/no-keyword.png';
import noResult from '../../assets/images/no-result.png';
import Moment from 'react-moment';

// Styles
import styles from './SearchPage.module.css';

const NO_KEYWORD_TEXT = 'Nothing here yet! they may be busy? \n But if you do a search, you may find someone available';
const NO_RESULT_TEXT = 'Oh crap! We\'ve got nothing for you!';

const ProjectSearchPage: React.FC = () => {
    const history = useHistory();
    const [keywords, setKeywords] = useState<string[]>([]);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [projectOptions, setProjectOptions] = useState<any[]>([]);

    const submitForm = (newKeywords = keywords): void => {
        const query = {
            keywords: newKeywords,
        };

        history.push({
            pathname: '/search/projects',
            search: utils.stringifyQuery(query)
        });
    };

    const handleKeywordChange = (newValue: any): void => {
        const newKeywords = newValue.map((x: any) => x.value);
        setKeywords(newKeywords);
        submitForm(newKeywords);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        submitForm();
    };

    const fetchProjectOptions = async () => {
        const projects = await getAllProject();
        const options: any[] = [];
        projects.forEach((element: any) => {
            options.push({
                label: `${element.name}`,
                value: `${element.name}`
            });
        });

        setProjectOptions(options);
    };

    useEffect(() => {
        const query = querystring.parse(history.location.search.replace('?', ''));
        const parsedKeywords: string[] = [];

        if (query.keywords) {
            if (Array.isArray(query.keywords)) {
                parsedKeywords.push(...query.keywords);
            } else {
                parsedKeywords.push(query.keywords);
            }
        }

        setKeywords(parsedKeywords);

        const initiateSearch = async (): Promise<void> => {
            const results = await searchProject(parsedKeywords);
            setSearchResults(results);
        };

        if (parsedKeywords.length === 0) {
            setSearchResults([]);
        } else {
            initiateSearch();
        }
    }, [history.location, projectOptions]);

    useEffect(() => {
        fetchProjectOptions();
    }, []);

    const renderProjectDetails = (project: any): JSX.Element => {
        return <ProjectCard project={project} />;
    };

    const renderSearchProjectResults = (): JSX.Element => {
        if (!searchResults) {
            return (<React.Fragment></React.Fragment>);
        }

        const result = searchResults.map((r: any, index: number) => {
            return (
                <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                        <Row key={`r-${index.toString()}`} className={'mb-2'}>
                            <Col className={styles.projectTitle}>{r.name}</Col>
                            <Col className={`text-center ${styles.projectDescription}`}>{r.description}</Col>
                            <Col className='text-center'>
                                {r.start_date ? <Moment format="ll">{r.start_date}</Moment> : 'N/A'}
                                {' - '}
                                {r.end_date ? <Moment format="ll">{r.end_date}</Moment> : 'N/A'}
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index.toString()} className={`px-3 ${styles.projectDetailContainer}`}>
                        {renderProjectDetails(r)}
                    </Accordion.Collapse>
                </Card>
            );
        });

        return (
            <>
                <Accordion defaultActiveKey='0'>
                    <Accordion.Toggle as={Card.Header} eventKey={'table-header'} className={`mb-1 ${styles.cardHeader}`} bsPrefix="cardHeader">
                        <Row>
                            <Col className={`${styles.tableHeader}`}>Project</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Description</Col>
                            <Col className={`text-center ${styles.tableHeader}`}>Duration</Col>
                        </Row>
                    </Accordion.Toggle>
                    {searchResults.length > 0 ? result : <NoSearchResult imageDisplay={noResult} textDisplay={NO_RESULT_TEXT} />}
                </Accordion>
            </>
        );
    };

    return (
        <>
            <Row className={'mt-4'}>
                <Col>
                    <SelectSearch
                        label={'KEYWORD: '}
                        options={projectOptions}
                        value={keywords.map((x: string) => ({ label: x, value: x }))}
                        isMulti={true}
                        onChange={handleKeywordChange}
                        onSubmit={handleSubmit}
                    />
                </Col>
            </Row>
            <Row className="pb-5">
                <Col>
                    {keywords.length > 0 && (
                        searchResults.length > 0 ?
                            <h5 className="font-weight-bold mb-4">{searchResults.length} Search Result for &quot;{keywords.join(', ')}&quot;</h5> :
                            null
                    )}
                    {keywords.length > 0 ?
                        renderSearchProjectResults()
                        :
                        <NoSearchResult imageDisplay={noKeyword} textDisplay={NO_KEYWORD_TEXT} />
                    }
                </Col>
            </Row>
        </>
    );
};

export default ProjectSearchPage;