import * as React from 'react';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { Stepper } from 'react-form-stepper';

// Services
import { useAuth } from '../../context/auth/Auth';
import { getAllCertifications } from '../../services/CertificationService';
import { getAllSkills } from '../../services/SkillService';
import { getAllProject } from '../../services/ProjectService';
import { getAllRoles } from '../../services/EmployeeRoleService';

// Components
import SkillsCertificationsEdit from './SkillsCertificationsEdit';
import Navigation from '../../components/NavMenu/NavMenu';
import PersonalInformation from './PersonalInformationEdit';
import ProjectEdit from './ProjectEdit';

// Styles
import styles from './ProfileEditPage.module.css';

interface Step {
    path: string;
    label: string;
}

const steps: Step[] = [{
    path: '/profile/edit/personal-information',
    label: 'Personal Information'
},{
    path: '/profile/edit/skills-and-certifications',
    label: 'Skills and Certifications'
},{
    path: '/profile/edit/projects',
    label: 'Projects'
}];

const ProfileEditPage: React.FC = () => {
    const { fetchCurrentUserEmployeeData } = useAuth();
    const location = useLocation();
    const [certifications, setCertifications] = useState<any[]>([]);
    const [skills, setSkills] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [roles, setRoles] = useState<any[]>([]);

    const fetchData = async () => {
        await fetchCurrentUserEmployeeData();
        const certs = await getAllCertifications();
        const skills = await getAllSkills();
        const projects = await getAllProject();
        const roles = await getAllRoles();

        setCertifications(certs);
        setSkills(skills);
        setProjects(projects);
        setRoles(roles);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const renderStepper = (stepPath: string): JSX.Element => {
        const stepIndex = steps.findIndex(x => x.path === stepPath);

        return (
            <Stepper
                activeStep={stepIndex}
                steps={steps.map(step => ({ label: step.label }))}
                styleConfig={{
                    activeBgColor: '#f4b331',
                    activeTextColor: '#ffffff',
                    completedBgColor: '#343A40',
                    completedTextColor: '#ffffff',
                    inactiveBgColor: '#e0e0e0',
                    inactiveTextColor: '#ffffff',
                    size: '57px',
                    circleFontSize: '1rem',
                    labelFontSize: '12px',
                    borderRadius: '50%',
                    fontWeight: 'bold'
                }}
                connectorStyleConfig={{
                    disabledColor: '#bdbdbd',
                    activeColor: '#f4b331',
                    completedColor: '#f4b331',
                    size: 2,
                    stepSize: '2em',
                    style: 'solid'
                }}
            />
        );
    };

    return (
        <>
            <Navigation />
            <Container>
                <div className={styles.headingsSection}>
                    <h2>SETUP YOUR ACCOUNT</h2>
                </div>
                <div className={styles.stepperSection}>
                    {renderStepper(location.pathname)}
                </div>
                <Route exact path="/profile/edit">
                    <Redirect to={steps[0].path} />
                </Route>
                <Route exact path="/profile/edit/personal-information">
                    <PersonalInformation />
                </Route>
                <Route exact path="/profile/edit/skills-and-certifications">
                    <SkillsCertificationsEdit 
                        certifications={certifications}
                        skills={skills}
                    />
                </Route>
                <Route exact path="/profile/edit/projects">
                    <ProjectEdit 
                        projects={projects}
                        roles={roles}
                    />
                </Route>
            </Container>
        </>
    );
};

export default ProfileEditPage;